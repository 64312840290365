import React from 'react';

function Privacy() {  
  return (
    <div className="overflow-y-auto mx-auto mt-12 justify-center">
     <h3 className="font-heading font-extrabold text-3xl text-center">Privacy Policy</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-32 md:mt-12 mx-6 md:mx-64 mb-12">
        <div className="space-y-5">
          <div>
            <h1 className="text-md font-semibold">WHAT INFORMATION DO WE COLLECT?</h1>
            <p className="text-sm">We collect information from you when you register on our site, subscribe to our 
              newsletter, respond to a survey or fill out a form. When registering on our site you
              may be asked to enter your: name or e-mail address. You may, however, 
              visit our site anonymously. 
            </p>
          </div>
          <div>
            <h1 className="text-md font-semibold">
              WHAT DO WE USE YOUR INFORMATION FOR?
            </h1>
            <p className="text-sm">
              Any of the information we collect from you may be used in one of the following ways:
            </p>
            <ul className="my-2 list-disc list-inside text-sm">
              <li>
                To improve our website (we continually strive to improve our website offerings 
                based on the information and feedback we receive from you)
              </li>
              <li>
                To improve customer service (your information helps us to more effectively respond
                to your customer service requests and support needs)
              </li>
              <li>
                To administer a contest, promotion, survey or other site feature
              </li>
              <li>To send periodic emails</li>
            </ul>
            <p className="text-sm">
              The email address you provide may be used to send you information, respond to
              inquiries, and/or other requests or questions. 
            </p>
          </div>
          <div>
            <h1 className="text-md font-semibold">
              HOW DO WE PROTECT YOUR INFORMATION?
            </h1>
            <p className="text-sm">
              We implement a variety of security measures to maintain the safety of your personal
              information when you enter, submit, or access your personal information.
            </p>
          </div>
          <div>
            <h1 className="text-md font-semibold">
              DO WE USE COOKIES?
            </h1>
            <p className="text-sm">
              We do not use cookies.
            </p>
          </div>
          <div>
            <h1 className="text-md font-semibold mt-4">
              DO WE DISCLOSE ANY INFORMATION TO OUTSIDE PARTIES?
            </h1>
            <p className="text-sm">
              We do not sell, trade, or otherwise transfer to outside parties your personally
              identifiable information. This does not include trusted third parties who assist
              us in operating our website, conducting our business, or servicing you, so long
              as those parties agree to keep this information confidential. We may also
              release your information when we believe release is appropriate to comply
              with the law, enforce our site policies, or protect ours or others rights,
              property, or safety. However, non-personally identifiable visitor information
              may be provided to other parties for marketing, advertising, or other uses.
            </p>
          </div>
        </div>
        <div className="space-y-5">
          <div>
            <h1 className="text-md font-semibold">
              ONLINE PRIVACY POLICY ONLY
            </h1>
            <p>
              This online privacy policy applies only to information collected through our website
              and not to information collected offline.
            </p>
          </div>
          <div>
            <h1 className="text-md font-semibold">
              YOUR CONSENT
            </h1>
            <p className="text-sm">
              By using our site, you consent to our Terms of Use and our Online Privacy Policy.
            </p>
          </div>
          <div>
            <h1 className="text-md font-semibold">
              CHANGES TO OUR PRIVACY POLICY
            </h1>
            <p className="text-sm">
              If we decide to change our privacy policy, we will post those changes on this page.
            </p>
          </div>
          <div>
            <h1 className="text-md font-semibold">
              CONTACTING US
            </h1>
            <p className="text-sm">
              If there are any questions regarding this privacy policy you may contact us
              using the information below.
            </p>
            <div className="mt-2">
              <a className="font-bold text-primary hover:text-accent" href="www.lahaciendafoods.com" target="_blank">
                <h2>www.lahaciendafoods.com</h2>
              </a>
              <ul>
                <li><h2>Ave. De Diego #D-50 Urb. Caribe</h2></li>
                <li><h2>San Juan 00926</h2></li>
                <li><h2>Puerto Rico</h2></li>
              </ul>
              <a className="text-primary hover:text-accent" href="mailto:info@lahaciedafoods.com">
                info@lahaciedafoods.com
              </a>
            </div>
          </div>
        </div>
      </div>  
    </div>
  );
}

export default Privacy;
