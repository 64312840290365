import React from 'react';
import { isMobile } from 'react-device-detect';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Menu, Transition } from '@headlessui/react';
import LeafLogo from '../../../../../assets/img/lhfleaf.png';
import cilantro from '../../../../../assets/img/lhf/cilantro.svg';
import goodies from '../../../../../assets/img/lhf/goodies.svg';
import seal from '../../../../../assets/img/lhf/seal.svg';
import goodiesleaftop from '../../../../../assets/img/lhf/goodiesandleaftop.svg';
import {
  locationAtom,
  storesDialogAtom,
} from '../../../../../atoms/Atoms';


function Content() {
  const { i18n, t } = useTranslation();
  const setOpen = useSetRecoilState(storesDialogAtom);
  const [location, setLocation] = useRecoilState(locationAtom);

  return (
    <main className="flex flex-col justify-start text-secondary font-medium container mx-auto p-4 md:p-16 space-y-2">
      <div className="text-xl md:text-2xl tracking-tight font-heading font-extrabold">
        {t('webstore_welcome')}
      </div>
      <div className="hidden">{t('slogan')}</div>
      <div className="flex flex-col mx-auto relative w-3/4">
        <Menu>
          {({ open }) => (
            <>
              <Menu.Button className="flex items-center justify-between font-medium text-white bg-accent ring-inset p-4 outline-none focus:outline-none transition duration-500 ease-in-out">
                <span>{t('select_service')}</span>
                <svg
                  className="w-5 h-5 ml-2 -mr-1"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </Menu.Button>

              <Transition
                show={open}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  static
                  className="text-white absolute right-0 w-full border-t origin-top-right bg-white divide-y divide-gray-100 outline-none"
                >
                  <div className="flex divide-x">
                    <div className="flex flex-col flex-1 items-center p-4 bg-accent">
                      <button
                        type="button"
                        onClick={() => setOpen(true)}
                      >
                      {t('pickup')}
                      </button>
                    </div>
                    <div className="flex flex-col flex-1 items-center p-4 bg-accent">
                      <button
                        type="button"
                        onClick={() => {
                          setLocation({
                            ...location,
                            modal: true,
                            page: 0,
                            current: 'locations', }) 
                          }}
                      >
                      {t('delivery')}
                      </button>
                    </div>
                  </div>

                  <div className="hidden py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="#account-settings"
                          className={`${
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700'
                          } flex justify-between w-full px-4 py-2 text-sm leading-5 text-left`}
                        >
                          Account settings
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="#support"
                          className={`${
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700'
                          } flex justify-between w-full px-4 py-2 text-sm leading-5 text-left`}
                        >
                          Support
                        </a>
                      )}
                    </Menu.Item>
                   
                  </div>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      </div>
    </main>
  );
}

export default Content;
