import React from 'react';
import { motion } from 'framer-motion';

function Animation({ children, className }) {
  return (
    <motion.div
      initial={{
        opacity: 0,
        y: 50,
      }}
      animate={{
        opacity: 1,
        y: 0,
      }}
      exit={{
        opacity: 0,
        y: 50,
      }}
      transition={{
        duration: 0.75,
      }}
      className={className}
    >
      {children}
    </motion.div>
  );
}

export default Animation;
