import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { storeAtom, timeSlotAtom } from '../../../atoms/Atoms';

function HomeLink() {
  const { i18n, t } = useTranslation();
  const store = useRecoilValue(storeAtom);
  const setTimeSlot = useSetRecoilState(timeSlotAtom);

  return (
    <div className="container justify-start flex flex-shrink-0 w-full text-xs tracking-tight p-4 space-x-1 truncate">
      <NavLink
        // onClick={() => { setTimeSlot(null); }}
        to={`/stores/${store && store.id}`}
        className="text-accent font-light hover:font-semibold transition duration-500 ease-in-out"
      >
        {t('back_to_shopping')}
      </NavLink>
    </div>
  );
}

export default HomeLink;
