import React, { useEffect } from 'react';
import LogRocket from 'logrocket';
import _ from 'lodash';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faShoppingBasket, faLanguage, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Link, useHistory, useParams, NavLink } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { Menu, Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import {
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
  useResetRecoilState
} from 'recoil';
import Logo from '../../../assets/img/LaHaciendaLogo.png';
import LeafLogo from '../../../assets/img/lhfleaf.png';
import {
  menuAtom,
  orderAtom,
  paymentAtom,
  serviceAtom,
  sidebarAtom,
  storeAtom,
  tokenAtom,
  userAtom,
} from '../../../atoms/Atoms';
import LoyaltySummary from '../LoyaltySummary';
import User from '../user/User';
import { businessesSelector } from '../../../atoms/Selectors';
import envConfig from '../../../envConfig';

function Header(props) {
  const { i18n, t } = useTranslation();
  const refreshStore = useResetRecoilState(businessesSelector);
  const history = useHistory();
  const { id } = useParams();
  const [user, setUser] = useRecoilState(userAtom);
  const setToken = useSetRecoilState(tokenAtom);
  const setPayment = useSetRecoilState(paymentAtom);
  const order = useRecoilValue(orderAtom);
  const setSidebar = useSetRecoilState(sidebarAtom);
  const store = useRecoilValue(storeAtom);
  const [menu, setMenu] = useRecoilState(menuAtom);
  const service = useRecoilValue(serviceAtom);

  const languages = [    
    {
      key: 'en',
      lang: 'en',
      name: 'English',
    },
    {
      key: 'es',
      lang: 'es',
      name: 'Español',
    },
  ];

  const { location } = props;

  useEffect(() => {
    if (store && store.id !== id) {
      if (location && location.pathname !== '/') {
        history.push('/');
      }
    }
  }, [store, order]);

  useEffect(() => {
    if (user && envConfig.UseLogRocket === 'true') {
      LogRocket.identify(user.id, {
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
      });
    }
  }, [user]);

  return (
    <header>
      <nav className="flex flex-shrink-0 bg-primary border-b-4 items-center justify-between px-4 h-20 text-sm font-semibold">
        <div className="flex items-center space-x-2">
          <Link to="/" className="flex flex-row">
            <img alt="Leaf" src={LeafLogo} className="w-6 md:w-10 h-6 md:h-10" />
            <img alt="Logo" src={Logo} className="w-28 md:w-48 h-auto" />
          </Link>
        </div>
        <div className="hidden md:flex flex-col justify-center mb-2">
          {store && (
            <div className={history.location.pathname === '/' ? 'hidden' : 'text-white text-right flex-row text-xs font-light'}>
              <NavLink
                to="/"
                className="font-light hover:font-bold transition duration-500 ease-in-out ml-1"
              >
                <div>{t('shopping')} {service === 0 ? t('pickup') : t('delivery')}</div>
                <div className="font-bold">{store?.name}</div>
                <div className="lowercase">({t('change')})</div>
              </NavLink>
            </div>
          )}
        </div>
        <div className="flex flex-col justify-end">
          {store && (
            <div className={history.location.pathname === '/' ? 'hidden' : 'flex md:hidden text-white flex-row text-xs font-light'}>
              {service === 0 && (
                <div className="text-xs tracking-tight">
                  {!isMobile ? `${t('shopping_for')} ${t('pickup_at')} ${store?.name}` : `${t('pickup')} | ${store?.name}`}
                </div>
              )}
              {service === 1 && (
                <div className="text-xs tracking-tight">
                  {!isMobile ? `${t('shopping_for')} ${t('delivery_at')} ${store?.name}` : `${t('delivery')} | ${store?.name}`}
                </div>
              )}
              <NavLink
                to="/"
                className="font-semibold hover:font-extrabold transition duration-500 ease-in-out ml-1"
              >
                {!isMobile ? '(change)' : <FontAwesomeIcon icon={faChevronDown} />}
              </NavLink>
            </div>
          )}
          <div className=" flex items-center justify-end">
            <Menu as="div" className="hidden relative text-3xl mr-4">
              <div className="relative flex">
                <Menu.Button className="hover:scale-90 transform group flex items-center leading-none ring-0 focus:ring-0 outline-none focus:outline-none transition duration-500 ease-in-out">
                <FontAwesomeIcon
                  icon={faLanguage}
                  className="text-white"
                />
                </Menu.Button>
              </div>
              <Transition
                as={React.Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Menu.Items className="absolute z-30 right-0 mt-2 w-32 ring-0 focus:ring-0 outline-none focus:outline-none">
                  <div className="rounded-lg shadow-lg overflow-hidden">
                    <div className="relative bg-white p-4 space-y-4 text-xs">
                      {_.map(languages, (lang) => (
                        <Menu.Item
                          as="button"
                          key={lang.key}
                          type="button"
                          onClick={() => {
                            i18n.changeLanguage(lang.lang).then(() => {
                              window.localStorage.setItem(
                                'lhf-lang',
                                JSON.stringify(lang.lang)
                              );
                            });
                          }}
                          className={classNames(
                            i18n.language === lang.lang && 'text-accent',
                            'group flex items-center hover:text-gray-500 space-x-2 ring-0 focus:ring-0 outline-none focus:outline-none transition ease-in-out duration-500'
                          )}
                        >
                          <FontAwesomeIcon icon={faLanguage} className="w-4 h-4 text-2xl" />
                          <span>{lang.name}</span>
                        </Menu.Item>
                      ))}
                    </div>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>    
            <button
              type="button"
              onClick={() => setMenu(!menu)}
              className="md:hidden hover:scale-90 transform h-10 w-10 px-2 rounded-md text-xl transition duration-200 ease-in-out ring-0 outline-none focus:ring-0 focus:outline-none"
            >
              <FontAwesomeIcon icon={faBars} className="text-white" />
            </button>
            {!user && (
              <div className="hidden md:flex space-x-2 pl-4">
                <NavLink
                  to="/login"
                  activeClassName="text-white"
                  className="font-semibold tracking-tight text-xs text-white hover:text-accent outline-none focus:outline-none ring-0 focus:ring-0 transition-colors duration-500 ease-linear"
                >
                  {t('signin')}
                </NavLink>
                <NavLink
                  to="/register"
                  activeClassName="text-white"
                  className="hidden font-semibold tracking-tight text-xs text-white hover:text-accent outline-none focus:outline-none ring-0 focus:ring-0 transition-colors duration-500 ease-linear"
                >
                  {t('signup')}
                </NavLink>
              </div>
            )}
            {user && (
            <Menu className="hidden md:block">
              {({ open }) => (
                <div className="relative">
                  <Menu.Button className="text-right flex md:space-x-2 hover:scale-90 transform items-center outline-none p-2 rounded-md focus:outline-none transition duration-500 ease-linear">
                    <User />
                  </Menu.Button>
                  {open && (
                    <Menu.Items
                      static
                      className="absolute z-50 right-0 mt-2 h-auto w-56 no-scrollbar overflow-auto bg-white divide-y divide-gray-100 rounded-xl shadow-lg outline-none focus:outline-none"
                    >
                      <Menu.Item
                        as="button"
                        type="button"
                        className="hidden p-4 w-full text-left font-medium text-xs tracking-tight hover:bg-gray-100 outline-none focus:outline-none transition duration-500 easy-in-out"
                        onClick={() => {
                          history.push('/profile/loyalty');
                        }}
                      >
                        <LoyaltySummary />
                      </Menu.Item>
                      <Menu.Item
                        as="button"
                        type="button"
                        className="p-4 w-full text-left font-medium text-xs tracking-tight hover:bg-gray-100 outline-none focus:outline-none transition duration-500 easy-in-out"
                        onClick={() => {
                          history.push('/profile/orders');
                        }}
                      >
                        {t('orders')}
                      </Menu.Item>
                      <Menu.Item
                        as="button"
                        type="button"
                        className="p-4 w-full text-left font-medium text-xs tracking-tight hover:bg-gray-100 outline-none focus:outline-none transition duration-500 easy-in-out"
                        onClick={() => {
                          history.push('/profile/settings');
                        }}
                      >
                        {t('my_account')}
                      </Menu.Item>
                      <Menu.Item
                        as="button"
                        type="button"
                        className="p-4 w-full text-left font-medium text-xs tracking-tight hover:bg-gray-100 outline-none focus:outline-none transition duration-500 easy-in-out"
                        onClick={() => {
                          history.push('/profile/password');
                        }}
                      >
                        {t('change_password')}
                      </Menu.Item>
                      <Menu.Item
                        as="button"
                        type="button"
                        className="p-4 w-full text-left font-medium text-xs tracking-tight hover:bg-gray-100 outline-none focus:outline-none transition duration-500 easy-in-out"
                        onClick={() => {
                          history.push('/profile/payment');
                        }}
                      >
                        {t('payment_methods')}
                      </Menu.Item>
                      <Menu.Item
                        as="button"
                        type="button"
                        className="p-4 w-full text-left font-medium text-xs tracking-tight hover:bg-gray-100 outline-none focus:outline-none transition duration-500 easy-in-out"
                        onClick={() => {
                          history.push('/profile/locations');
                        }}
                      >
                        {t('my_locations')}
                      </Menu.Item>
                      <Menu.Item
                        as="button"
                        type="button"
                        className="font-semibold p-4 w-full text-left font-medium text-xs tracking-tight bg-gray-800 text-white hover:bg-gray-700 outline-none focus:outline-none transition duration-500 easy-in-out"
                        onClick={() => {
                          setUser(null);
                          setToken(null);
                          setPayment({
                            card: null,
                            cards: null,
                            loading: true,
                            error: null,
                            options: null,
                          });
                          refreshStore();
                          window.localStorage.removeItem('lhf-user');
                          window.localStorage.removeItem('lhf-card');
                          window.localStorage.removeItem('lhf-state');
                          window.localStorage.removeItem('lhf-atoken');
                          history.push('/login');
                        }}
                      >
                        {t('signout')}
                      </Menu.Item>
                    </Menu.Items>
                  )}
                </div>
              )}
            </Menu>
          )}
          <button
            type="button"
            className="mr-2 flex items-center md:space-x-2 text-lg relative p-2 hover:scale-90 transform outline-none focus:outline-none ring-0 focus:ring-0 transition duration-500 ease-linear"
            onClick={() => setSidebar(true)}
          >
              {/* <span className="hidden text-xs font-semibold text-white
              tracking-tight hidden md:flex">
                ${checkout.subtotal}
              </span> */}
              <div className="relative">
                <FontAwesomeIcon
                  icon={faShoppingBasket}
                  className="text-white"
                />
                <AnimatePresence>
                  {order.length > 0 && (
                    <motion.div
                      key="count"
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      exit={{ scale: 0 }}
                      className="absolute -bottom-2 -left-2 flex items-center justify-center text-white font-bold h-5 w-5 rounded-full bg-accent"
                    >
                      <motion.span
                        key={`count-${order.length}`}
                        initial={{ opacity: 0, y: '100%' }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0 }}
                        style={{ fontSize: 9 }}
                      >
                        {order.length}
                      </motion.span>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
          </button>
          </div>
        </div>
        <div className=" hidden flex items-center">    
          <Menu as="div" className="relative text-3xl mr-4">
            <div className="relative flex">
              <Menu.Button className="hover:scale-90 transform group flex items-center leading-none ring-0 focus:ring-0 outline-none focus:outline-none transition duration-500 ease-in-out">
              <FontAwesomeIcon
                icon={faLanguage}
                className="text-white"
              />
              </Menu.Button>
            </div>
            <Transition
              as={React.Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Menu.Items className="absolute z-30 right-0 mt-2 w-32 ring-0 focus:ring-0 outline-none focus:outline-none">
                <div className="rounded-lg shadow-lg overflow-hidden">
                  <div className="relative bg-white p-4 space-y-4 text-xs">
                    {_.map(languages, (lang) => (
                      <Menu.Item
                        as="button"
                        key={lang.key}
                        type="button"
                        onClick={() => {
                          i18n.changeLanguage(lang.lang).then(() => {
                            window.localStorage.setItem(
                              'lhf-lang',
                              JSON.stringify(lang.lang)
                            );
                          });
                        }}
                        className={classNames(
                          i18n.language === lang.lang && 'text-accent',
                          'group flex items-center hover:text-gray-500 space-x-2 ring-0 focus:ring-0 outline-none focus:outline-none transition ease-in-out duration-500'
                        )}
                      >
                        <FontAwesomeIcon icon={faLanguage} className="w-4 h-4 text-2xl" />
                        <span>{lang.name}</span>
                      </Menu.Item>
                    ))}
                  </div>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>    
          <button
            type="button"
            className="ml-4 flex items-center md:space-x-2 text-lg relative p-2 hover:scale-90 transform outline-none focus:outline-none ring-0 focus:ring-0 transition duration-500 ease-linear"
            onClick={() => setSidebar(true)}
          >
            {/* <span className="hidden text-xs font-semibold text-white
             tracking-tight hidden md:flex">
              ${checkout.subtotal}
            </span> */}
            <div className="relative">
              <FontAwesomeIcon
                icon={faShoppingBasket}
                className="text-white"
              />
              <AnimatePresence>
                {order.length > 0 && (
                  <motion.div
                    key="count"
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    exit={{ scale: 0 }}
                    className="absolute -bottom-2 -left-2 flex items-center justify-center text-white font-bold h-5 w-5 rounded-full bg-accent"
                  >
                    <motion.span
                      key={`count-${order.length}`}
                      initial={{ opacity: 0, y: '100%' }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0 }}
                      style={{ fontSize: 9 }}
                    >
                      {order.length}
                    </motion.span>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </button>
          <button
            type="button"
            onClick={() => setMenu(!menu)}
            className="md:hidden hover:scale-90 transform h-10 w-10 px-2 rounded-md text-xl transition duration-200 ease-in-out ring-0 outline-none focus:ring-0 focus:outline-none"
          >
            <FontAwesomeIcon icon={faBars} className="text-white" />
          </button>
          {!user && (
            <div className="hidden md:flex space-x-2 pl-4">
              <NavLink
                to="/login"
                activeClassName="text-white"
                className="font-semibold tracking-tight text-xs text-white hover:text-accent outline-none focus:outline-none ring-0 focus:ring-0 transition-colors duration-500 ease-linear"
              >
                {t('signin')}
              </NavLink>
              <NavLink
                to="/register"
                activeClassName="text-white"
                className="hidden font-semibold tracking-tight text-xs text-white hover:text-accent outline-none focus:outline-none ring-0 focus:ring-0 transition-colors duration-500 ease-linear"
              >
                 {t('signup')}
              </NavLink>
            </div>
          )}
          {user && (
            <Menu className="hidden md:block">
              {({ open }) => (
                <div className="relative">
                  <Menu.Button className="text-right flex md:space-x-2 hover:scale-90 transform items-center outline-none p-2 rounded-md focus:outline-none transition duration-500 ease-linear">
                    <User />
                  </Menu.Button>
                  {open && (
                    <Menu.Items
                      static
                      className="absolute z-50 right-0 mt-2 h-auto w-56 no-scrollbar overflow-auto bg-white divide-y divide-gray-100 rounded-xl shadow-lg outline-none focus:outline-none"
                    >
                      <Menu.Item
                        as="button"
                        type="button"
                        className="hidden p-4 w-full text-left font-medium text-xs tracking-tight hover:bg-gray-100 outline-none focus:outline-none transition duration-500 easy-in-out"
                        onClick={() => {
                          history.push('/profile/loyalty');
                        }}
                      >
                        <LoyaltySummary />
                      </Menu.Item>
                      <Menu.Item
                        as="button"
                        type="button"
                        className="p-4 w-full text-left font-medium text-xs tracking-tight hover:bg-gray-100 outline-none focus:outline-none transition duration-500 easy-in-out"
                        onClick={() => {
                          history.push('/profile/orders');
                        }}
                      >
                        {t('orders')}
                      </Menu.Item>
                      <Menu.Item
                        as="button"
                        type="button"
                        className="p-4 w-full text-left font-medium text-xs tracking-tight hover:bg-gray-100 outline-none focus:outline-none transition duration-500 easy-in-out"
                        onClick={() => {
                          history.push('/profile/settings');
                        }}
                      >
                        {t('my_account')}
                      </Menu.Item>
                      <Menu.Item
                        as="button"
                        type="button"
                        className="p-4 w-full text-left font-medium text-xs tracking-tight hover:bg-gray-100 outline-none focus:outline-none transition duration-500 easy-in-out"
                        onClick={() => {
                          history.push('/profile/password');
                        }}
                      >
                        {t('change_password')}
                      </Menu.Item>
                      <Menu.Item
                        as="button"
                        type="button"
                        className="p-4 w-full text-left font-medium text-xs tracking-tight hover:bg-gray-100 outline-none focus:outline-none transition duration-500 easy-in-out"
                        onClick={() => {
                          history.push('/profile/payment');
                        }}
                      >
                        {t('payment_methods')}
                      </Menu.Item>
                      <Menu.Item
                        as="button"
                        type="button"
                        className="p-4 w-full text-left font-medium text-xs tracking-tight hover:bg-gray-100 outline-none focus:outline-none transition duration-500 easy-in-out"
                        onClick={() => {
                          history.push('/profile/locations');
                        }}
                      >
                        {t('my_locations')}
                      </Menu.Item>
                      <Menu.Item
                        as="button"
                        type="button"
                        className="font-semibold p-4 w-full text-left font-medium text-xs tracking-tight bg-gray-800 text-white hover:bg-gray-700 outline-none focus:outline-none transition duration-500 easy-in-out"
                        onClick={() => {
                          setUser(null);
                          setToken(null);
                          setPayment({
                            card: null,
                            cards: null,
                            loading: true,
                            error: null,
                            options: null,
                          });
                          refreshStore();
                          window.localStorage.removeItem('lhf-user');
                          window.localStorage.removeItem('lhf-card');
                          window.localStorage.removeItem('lhf-state');
                          window.localStorage.removeItem('lhf-atoken');
                          history.push('/login');
                        }}
                      >
                        {t('signout')}
                      </Menu.Item>
                    </Menu.Items>
                  )}
                </div>
              )}
            </Menu>
          )}
        </div>
      </nav>
    </header>
  );
}

export default Header;
