import React, { useEffect } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Menu, Transition } from '@headlessui/react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import {
  locationAtom,
  notificationsAtom,
  tokenAtom,
} from '../../../../../../atoms/Atoms';
import api from '../../../../../../api/api';
import Loader from '../../../../../shared-components/loader/Loader';

function Items() {
  const { t } = useTranslation();
  const token = useRecoilValue(tokenAtom);
  const [locations, setLocations] = useRecoilState(locationAtom);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);

  const container = {
    visible: {
      opacity: 1,
      transition: {
        when: 'beforeChildren',
        staggerChildren: 0.3,
      },
    },
    hidden: {
      opacity: 0,
      transition: {
        when: 'afterChildren',
      },
    },
  };

  const item = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: -100 },
  };

  const getLocations = () => {
    api
      .get('user/locations', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.error) {
          setNotifications([
            ...notifications,
            {
              title: `${t('oops')}`,
              description:
                `${t('locations_load_error')}`,
              error: true,
              modal: false,
            },
          ]);
        }

        setLocations({
          ...locations,
          locations: response.data.data.results,
          loading: false,
        });
      })
      .catch((error) => {
        setNotifications([
          ...notifications,
          {
            title: `${t('oops')}`,
            description: `${t('locations_load_error')} - ${error.message}`,
            error: true,
          },
        ]);
        setLocations({
          ...locations,
          loading: false,
        });
      });
  };

  const deleteLocation = (id) => {
    api
      .delete(`user/location/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        const filter = _.filter(
          locations.locations,
          (location) => location.id !== id
        );

        setLocations({
          ...locations,
          locations: filter,
        });
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (token) {
      getLocations();
    }
  }, [token]);

  if (locations.loading) {
    return (
      <div className="p-4">
        <Loader color="bg-gray-500" />
      </div>
    );
  }

  return (
    <motion.div
      initial="hidden"
      exit="hidden"
      variants={container}
      animate={locations.locations.length > 0 ? 'visible' : 'hidden'}
      className="divide-y"
    >
      {_.map(locations.locations, (l) => (
        <motion.div
          key={l.id}
          variants={item}
          className="flex items-center justify-between text-xs py-3 px-2"
        >
          <div>
            <div className="font-semibold">{l.name}</div>
            <div className="text-gray-500">{l.addressLine}</div>
          </div>
          <Menu as="div" className="relative flex justify-end items-center">
            {({ open }) => (
              <>
                <Menu.Button className="w-8 h-8 inline-flex items-center justify-center rounded-md hover:bg-gray-100 outline-none ring-0 focus:outline-none focus:ring-0 transition duration-500 ease-linear">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                    />
                  </svg>
                </Menu.Button>
                <Transition
                  show={open}
                  as={React.Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    static
                    className="mx-3 origin-top-right absolute right-7 top-0 w-48 mt-1 divide-y divide-gray-100 rounded-xl shadow-lg z-10 bg-white text-xs ring-0 focus:outline-none"
                  >
                    <Menu.Item
                      as="button"
                      type="button"
                      className="rounded-b-xl text-gray-700 group flex items-center space-x-2 p-4 w-full text-left font-medium text-xs tracking-tight hover:bg-gray-100 outline-none focus:outline-none transition duration-500 easy-in-out"
                      onClick={() => deleteLocation(l.id)}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                      <div>{t('remove')}</div>
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>
        </motion.div>
      ))}
    </motion.div>
  );
}

export default Items;
