import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import './shared-components/PriceCheck.css';
import { useRecoilValueLoadable } from 'recoil';
import { businessesSelector } from '../../../atoms/Selectors';
import Loader from '../../shared-components/loader/Loader';
import Select from '../../shared-components/form/Select';
import api from '../../../api/api';
import { mapTaxRule, thousand, toCurrency, percent } from '../../../utils';


function PriceCheck() {
  const [selectedStore, setSelectedStore] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [showBackButton, setShowBackButton] = useState(false);
  const stores = useRecoilValueLoadable(businessesSelector);
  const { t, i18n } = useTranslation();
  const [productDetails, setProductDetails] = useState();
  const [searching, setSearching] = useState(false);
  const [count, setCount] = useState(0);
  const [lastSearch, setLastSearch] = useState(null);

  const copySearchTerm = () => {
    navigator.clipboard.writeText(lastSearch);
  };

  function mapData(data) {
    const filter = _.map(data, (p, index) => ({
      ...p,
      key: index,
      visible: p.isVisible,
      available: p.isAvailable,
      stock: p.quantityOnHand,
      price: p.basePrice,
      stateTaxPct: `${percent(p.stateTaxPct)} %`,
      taxRule: mapTaxRule(p.taxRule),
      munTaxPct: `${percent(p.munTaxPct)} %`,
    }));

    if (selectedStore !== 0) {
      return _.filter(filter, (o) => o.businessName === selectedStore);
    }
    return filter;
  }

  function mapProduct(data) {
    return _.map(data, (p, index) => ({
      ...p,
      key: index,
      name: p.completeName,
      image: p.itemImage,
      storeItems: mapData(p.storeItems),
    })).filter((product) => product.storeItems.length > 0);
  }

  const getProducts = () => {
    setSearching(true);
    api
      .post('businesses/price/check', {
        Query: searchTerm,
        Page: 0,
        Size: 10
      })
      .then((response) => {
        setSearching(false);
        if (response.data.error) {
          console.log(response.data.error);
        } else {
          setProductDetails(mapProduct(response.data.data.items));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="overflow-y-scroll">
      {/* Your main content */}
      {(stores.state === 'loading') && (
        <div className="landing-page">
          <div className="flex flex-1 flex-col mt-4 items-center justify-center h-20">
            <Loader />
          </div>
        </div>
      )}

      <div className="fixed w-full bg-gray-100 shadow-md">
        <div className="search-bar w-4/5">
          <Select
            onChange={(e) => setSelectedStore(e.target.value)}
            value={selectedStore}
            className="select-dropdown"
            options={[
              {
                key: 'placeholder',
                value: null,
                text: t('select_store'),
              },
              ...(stores.state === 'hasValue' ? stores?.contents?.data?.data?.results?.map((s, index) => ({
                key: index,
                value: s.name,
                text: s.name,
              })) : []),
              ]}
          />
          {/* Search Bar component */}
          <input
            type="search"
            placeholder="Search..."
            disabled={!selectedStore}
            value={searchTerm}
            onKeyDownCapture={(e) => {
              if (e.key === 'Enter') {
                getProducts();
              }
            }}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-input disabled:cursor-not-allowed disabled:opacity-50"
          />
          <button
            type="button"
            disabled={!selectedStore}
            onClick={() => { getProducts(); }}
            className="search-button disabled:cursor-not-allowed disabled:opacity-50"
          >
            {t('search')}
          </button>
        </div>
        <div className="justify-center mx-auto pl-4 -mt-6 mb-2 items-center w-4/5">
          {t('showing')}: {productDetails?.length}
        </div>
      </div>

      {selectedStore && (
        <div className="mt-28">
          {(searching) && (
            <div className=" flex flex-1 flex-col mt-4 items-center justify-center h-20">
              <Loader />
            </div>
          )}
          {!searching && (
            <div className="search-results-container">
              <div className="search-results">
                {/* Search Results component */}
                {productDetails ? (
                  <div className="search-results-inner">
                    {productDetails.length > 0 ? (
                      productDetails.map((product, index) => (
                        <div key={product.key} className="product-details shadow-lg grid grid-cols-7 gap-4">
                          <div className="rounded-xl col-span-7 md:col-span-1 bg-white">
                            <img src={product.image} alt={product.name} className="mx-auto max-h-40 md:max-h-full" />
                          </div>
                          <div className="col-span-7 md:col-span-6 w-full">
                            <div className="grid grid-cols-3 gap-2 my-2">
                              <div className="col-span-3 lg:col-span-1 w-full">
                                <strong>{i18n.language === 'en' ? product.completeName : product.completeNameSpa}</strong>
                                <div><strong>GTIN:</strong> {product.gtin}</div>
                                <div><strong>{t('division')}:</strong> {product.division}</div>
                                <div><strong>{t('id_required')}:</strong> {product.idRequired ? t('yes') : t('no')}</div>
                                <div><strong>{t('is_alcoholic')}:</strong> {product.isAlcoholicBeverage ? t('yes') : t('no')}</div>
                                <div><strong>{t('is_ebt')}:</strong> {product.isEbt ? t('yes') : t('no')}</div>
                                <div><strong>{t('is_perishable')}:</strong> {product.isPerishable ? t('yes') : t('no')}</div>
                                <div><strong>{t('is_package_sensitive')}:</strong> {product.isPackagingSensitive ? t('yes') : t('no')}</div>
                                <div><strong>{t('is_temperature_sensitive')}:</strong> {product.isTemperatureSensitive ? t('yes') : t('no')}</div>
                              </div>
                              <div className="col-span-3 md:col-span-2 lg:col-span-1 w-full">
                                <div><strong>UPC:</strong> {product.storeItems[0]?.upc}</div>
                                <div><strong>{t('is_visible')}:</strong> {product.storeItems[0]?.isVisible ? t('yes') : t('no')}</div>
                                <div><strong>{t('is_available')}:</strong> {product.storeItems[0]?.isAvailable ? t('yes') : t('no')}</div>
                                <div><strong>{t('is_random_weight')}:</strong> {product.storeItems[0]?.hasRandomWeight ? t('yes') : t('no')}</div>
                                <div><strong>{t('product_group')}:</strong> {product.storeItems[0]?.productGroup}</div>
                                <div><strong>{t('department')}:</strong> {i18n.language === 'en' ? product.storeItems[0]?.departmentNameEng : product.storeItems[0]?.departmentNameSpa}</div>
                                <div><strong>{t('category')}:</strong> {i18n.language === 'en' ? product.storeItems[0]?.sectionNameEng : product.storeItems[0]?.sectionNameSpa}</div>
                                <div><strong>{t('stock')}:</strong> {thousand(product.storeItems[0]?.quantityOnHand)}</div>
                              </div>
                              <div className="col-span-3 md:col-span-1 w-full">
                                <div><strong>{t('base_price')}:</strong> {toCurrency(product.storeItems[0]?.basePrice)}</div>
                                <div><strong>{t('base_multiple')}:</strong> {thousand(product.storeItems[0]?.baseMultiple)}</div>
                                <div><strong>{t('cost')}:</strong> {toCurrency(product.storeItems[0]?.cost)}</div>
                                <div><strong>{t('pack')}:</strong> {thousand(product.storeItems[0]?.pack)}</div>
                                <div><strong>{t('tax_rule')}:</strong> {product.storeItems[0]?.taxRule}</div>
                                <div><strong>{t('state_tax')}:</strong> {product.storeItems[0]?.stateTaxPct}</div>
                                <div><strong>{t('municipal_tax')}:</strong> {product.storeItems[0]?.munTaxPct}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="no-results-message">
                        {t('no_items_found')}
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default PriceCheck;
