import axios from 'axios';
import envConfig from '../envConfig';

const api = axios.create({
  baseURL: envConfig.ApiUrl,
  headers: {
    'Ocp-Apim-Subscription-Key': envConfig.ApiKey,
    Authorization: `Bearer ${window.localStorage.getItem('lhf-atoken')}`
  },
});
api.interceptors.request.use(
  (request) => {
    request.headers['Accept-Language'] = `${window.localStorage.getItem('lhf-lang')}-US`.replaceAll('"', '');
    request.headers.Authorization = `Bearer ${window.localStorage.getItem('lhf-atoken')}`.replaceAll('"', '');
    return request;
  }
);
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      window.location = '/login';
      window.localStorage.removeItem('lhf-atoken');
      window.localStorage.removeItem('lhf-state');
      window.localStorage.removeItem('lhf-user');
      window.localStorage.removeItem('lhf-card');
    } else {
      return Promise.reject(error);
    }
  }
);

export default api;
