export const phoneNumber = (phone) => {
  const cleaned = `${phone}`.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return null;
};

// Desktop animation variants for backdrop
export const backdropVariants = {
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.5,
    },
  },
};

// animation variants for details-modal
export const modalVariants = {
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 1,
      type: 'spring',
    },
  },
  hidden: {
    opacity: 0,
    scale: 0,
    transition: {
      duration: 1,
      type: 'spring',
    },
  },
};

export const mapTaxRule = (rule) => {
  switch (rule) {
    case 0:
      return 'No Tax';
    case 1:
      return 'Municipal Tax Only';
    case 2:
      return 'State Tax Only';
    case 3:
      return 'State and Municipal Tax (Both)';
    default:
      return 'undefined';
  }
};
// animation variants for sidebar
export const sidebarVariants = {
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.5,
    },
  },
  hidden: {
    opacity: 0,
    x: '100%',
    transition: {
      duration: 0.5,
      type: 'spring',
    },
  },
};

// animation variants for product list
export const containerVariants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2, delay: 1 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1, delay: 1 },
  },
};

export const productVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

export const thousand = (n) => {
  let number = n;
  if (number === null || number === undefined) {
    return '0';
  }
  if (number) {
    number = parseFloat(number.toString().replace(',', ''));
    if (number % 1 === 0) {
      number = number.toFixed(0);
    } else {
      number = number.toFixed(2);
    }
    const parts = number.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const result = parts.join('.');
    return result;
  }
  return '0';
};

export const percent = (value) => {
  if (value) {
    return thousand((value * 100).toFixed(2));
  }
  return '0';
};

export const toCurrency = (number, removeDecimals) => {
  try {
    if (!number) {
      return '-';
    }
    const wasNegative = number < 0;
    let fixed = 0;
    if (wasNegative) {
      fixed = (-1 * number).toFixed(2);
      // console.log(fixed);
    } else {
      fixed = number.toFixed(2);
    }
    if (wasNegative) {
      return `-$${thousand(fixed, removeDecimals)}`;
    }
    return `$${thousand(fixed, removeDecimals)}`;
  } catch (error) {
    return '-';
  }
};
