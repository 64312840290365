import React, { useEffect } from 'react';
import _ from 'lodash';
import { Menu } from '@headlessui/react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faSearch } from '@fortawesome/free-solid-svg-icons';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  locationAtom,
  notificationsAtom,
  searchBrandAtom,
  searchPageAtom,
  searchStringAtom,
  serviceAtom,
  storeAtom,
} from '../../../../atoms/Atoms';
import api from '../../../../api/api';
import Navigation from '../../navigation/Navigation';
import envConfig from '../../../../envConfig';

function Subheader({ showSearch, showDepartments, showCategories }) {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const setFilter = useSetRecoilState(searchBrandAtom);
  const store = useRecoilValue(storeAtom);
  const [searchString, setSearchString] = useRecoilState(searchStringAtom);
  const [search, setSearch] = useRecoilState(searchPageAtom);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);

  const getProducts = () => {
    if (location.pathname !== `/stores/${store.id}/search`) {
      history.push(`/stores/${store.id}/search`);
    }

    setFilter('');

    setSearch({
      loading: true,
      products: [],
      brands: [],
      page: 0
    });

    api
      .post(`catalog/search/${store.id}/0/${envConfig.SearchItemsPageSize}`, {
        SearchType: 0,
        searchString,
      })
      .then((response) => {
        if (response.data.error) {
          setNotifications([
            ...notifications,
            {
              title: `${t('oops')}`,
              description:
              `${t('search_error')}`,
              error: true,
            },
          ]);
          setSearch({
            loading: false,
            products: [],
            brands: [],
            page: 0
          });
        } else {
          const brands = _.map(
            _.uniqBy(response.data.data.items, 'brand'),
            (item) => item.brand
          );
          setSearch({
            ...search,
            loading: false,
            products: response.data.data.items,
            brands,
            page: 0,
            loadMore: response.data.data.items.length === envConfig.SearchItemsPageSize
          });
        }
      })
      .catch((error) => {
        setSearch({
          loading: false,
          products: [],
          brands: [],
          page: 0,
        });
        setNotifications([
          ...notifications,
          {
            title: `${t('oops')}`,
            description:
            `${t('search_error')}`,
            error: true,
          },
        ]);
      });
  };

  useEffect(() => {
    if (location.pathname !== `/stores/${store?.id}/search`) {
      setSearchString('');
    }
  }, []);

  if (store) {
    return (
      <div className="bg-secondary border-b items-center">
        {showSearch && (
          <div className="flex md:hidden flex-row">
            <div className="flex mx-2 mt-3 h-10 flex-1 md:flex-none border-2 border-gray-400 rounded-3xl">
              <input
                id="serachInput"
                className="focus:ring-0 h-10 block border-none w-full bg-transparent rounded-l-md text-white placeholder-gray-400 font-medium text-xs tracking-tight"
                type="search"
                name="search"
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                value={searchString}
                onKeyDown={(event) => {
                  if (searchString && event.key === 'Enter') getProducts();
                }}
                onChange={(event) => setSearchString(event.target.value)}
                placeholder={`${t('search_products')}...`}
              />
              <button
                type="button"
                onClick={() => {
                  if (searchString) getProducts();
                }}
                className="md:full inline-flex items-center space-x-2 text-gray-100 hover:text-gray-50 px-4 font-medium tracking-tight focus:outline-none transition duration-500 ease-in-out"
              >
                <FontAwesomeIcon icon={faSearch} />
              </button>
            </div>
          </div>  
        )}
        <div className="flex flex-row">
          {/* <nav className="hidden md:flex flex-1 items-center space-x-3">
            {_.map(menuItems, (e) => (
              <NavLink
                key={e.id}
                exact
                activeClassName="text-white underline"
                to={e.to}
                className="flex items-center text-gray-300 hover:text-white hover:underline text-xs
                font-mediumtracking-tight transition duration-500 ease-in-out outline-none
                focus:outline-none ring-0 focus:ring-0"
              >
                <div className="truncate">{e.text}</div>
              </NavLink>
            ))}
            {location && service === 1 && (
              <Menu>
                <div className="relative hidden">
                  <Menu.Button className="w-32 flex items-center text-white hover:underline text-xs
                  font-medium tracking-tight transition duration-500 ease-in-out outline-none
                  focus:outline-none ring-0 focus:ring-0">
                    <div className="truncate">
                      {locations.location?.addressLine}
                    </div>
                    <FontAwesomeIcon icon={faChevronDown} />
                  </Menu.Button>
                  <Menu.Items className="text-xs divide-y absolute z-50 transform -translate-x-1/2
                  left-1/2 mt-2 h-auto w-60 no-scrollbar overflow-auto bg-white divide-y
                  divide-gray-100 rounded-xl shadow-lg outline-none focus:outline-none">
                    <Menu.Item
                      as="div"
                      className="bg-gray-100 font-semibold text-center p-4 ring-0 outline-none
                      focus:ring-0 focus:outline-none"
                    >
                      ¿A dónde quierés pedir hoy?
                    </Menu.Item>
                    <Menu.Item
                      as="div"
                      className="p-4 ring-0 outline-none focus:ring-0 focus:outline-none"
                    >
                      <div className="text-gray-400 mb-2">Dirección Actual</div>
                      <div className="font-semibold">
                        {locations.location?.name}
                      </div>
                      <div>{locations.location?.addressLine}</div>
                    </Menu.Item>
                    <Menu.Item
                      as="button"
                      type="buttton"
                      onClick={() => {
                        setLocations({
                          ...locations,
                          modal: true,
                          page: 0,
                          current: 'locations',
                        });
                      }}
                      className="flex items-center justify-center text-center font-semibold p-4
                      w-full text-left font-medium text-xs tracking-tight bg-gray-800 text-white
                      hover:bg-gray-700 outline-none focus:outline-none ring-0 focus:ring-0
                      transition duration-500 easy-in-out"
                    >
                      {t('change_address')}
                    </Menu.Item>
                  </Menu.Items>
                </div>
              </Menu>
            )}
            {store && store?.locations && (
              <Menu hidden>
                <div className="relative">
                  <Menu.Button className="flex items-center space-x-2 text-gray-300
                  hover:text-white hover:underline text-xs font-medium tracking-tight transition
                  duration-500 ease-in-out outline-none focus:outline-none ring-0 focus:ring-0">
                    <div className="truncate">{t('contact')}</div>
                    <FontAwesomeIcon icon={faChevronDown} />
                  </Menu.Button>
                  <Menu.Items className="text-xs divide-y absolute z-50 transform -translate-x-1/2
                  left-1/2 mt-2 h-auto w-60 no-scrollbar overflow-auto bg-white divide-y
                  divide-gray-100 rounded-xl shadow-lg outline-none focus:outline-none">
                    <Menu.Item
                      as="div"
                      className="p-4 ring-0 outline-none focus:ring-0 focus:outline-none"
                    >
                      <div className="font-semibold">
                        {store.locations[0].name}
                      </div>
                      <div className="text-gray-400">
                        {store.locations[0].address1}.{' '}
                        {store.locations[0].address2}
                      </div>
                      <div className="text-gray-400">
                       {`${t('Phone')}`}: {phoneNumber(store.locations[0].orderPhone)}
                      </div>
                      <div className="text-gray-400">
                        E-mail: {store.locations[0].orderEmail}
                      </div>
                    </Menu.Item>
                    <Menu.Item
                      as="a"
                      href={`tel:${store.locations[0].orderPhone}`}
                      className="flex items-center justify-center text-center
                      font-semibold p-4 w-full
                      text-left font-medium text-xs tracking-tight bg-gray-800 text-white
                      hover:bg-gray-700 outline-none focus:outline-none ring-0 focus:ring-0
                      transition duration-500 easy-in-out"
                    >
                      {t('Call')}
                    </Menu.Item>
                  </Menu.Items>
                </div>
              </Menu>
            )}
          </nav> */}
          <div className="flex flex-1">
            <Navigation
              showDepartments={showDepartments ?? false}
              showCategories={showCategories ?? false}
            />
          </div>
          {showSearch && (
            <div className="hidden md:flex h-10 mt-2 mr-2 flex-1 md:flex-none border-2 border-gray-400 rounded-3xl">
              <input
                className="focus:ring-0 h-10 block border-none w-full bg-transparent rounded-l-md text-white placeholder-gray-400 font-medium text-xs tracking-tight"
                type="search"
                name="search"
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                value={searchString}
                onKeyDown={(event) => {
                  if (searchString && event.key === 'Enter') getProducts();
                }}
                onChange={(event) => setSearchString(event.target.value)}
                placeholder={`${t('search_products')}...`}
              />
              <button
                type="button"
                onClick={() => {
                  if (searchString) getProducts();
                }}
                className="md:full inline-flex items-center space-x-2 text-gray-100 hover:text-gray-50 px-4 font-medium tracking-tight focus:outline-none transition duration-500 ease-in-out"
              >
                <FontAwesomeIcon icon={faSearch} />
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }

  return null;
}

export default Subheader;
