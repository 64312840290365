import React from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { toLocalTime } from '../../functions';

function ItemTimeConstraint({ product }) {
  const { t } = useTranslation();
  const hasConstraint = product?.timeConstraint?.hourDelay > 0 ||
    product?.timeConstraint?.minuteDelay > 0 ||
    !product?.timeConstraint?.availableOnMondays ||
    !product?.timeConstraint?.availableOnTuesdays ||
    !product?.timeConstraint?.availableOnWednesdays ||
    !product?.timeConstraint?.availableOnThursdays ||
    !product?.timeConstraint?.availableOnFridays ||
    !product?.timeConstraint?.availableOnSaturdays ||
    !product?.timeConstraint?.availableOnSundays ||
    product?.timeConstraint?.limitDate;
  const days = product?.timeConstraint?.hourDelay % 24;
  const hours = product?.timeConstraint?.hourDelay > 23 ?
    product?.timeConstraint?.hourDelay - (days * 24) :
    product?.timeConstraint?.hourDelay;
  const minutes = product?.timeConstraint?.minuteDelay;
  const availDays = [];
  if (!product?.timeConstraint?.availableOnMondays ||
        !product?.timeConstraint?.availableOnTuesdays ||
        !product?.timeConstraint?.availableOnWednesdays ||
        !product?.timeConstraint?.availableOnThursdays ||
        !product?.timeConstraint?.availableOnFridays ||
        !product?.timeConstraint?.availableOnSaturdays ||
        !product?.timeConstraint?.availableOnSundays) {
    if (product?.timeConstraint?.availableOnMondays) {
      availDays.push('monday');
    }
    if (product?.timeConstraint?.availableOnTuesdays) {
      availDays.push('tuesday');
    }
    if (product?.timeConstraint?.availableOnWednesdays) {
        availDays.push('wednesday');
    }
    if (product?.timeConstraint?.availableOnThursdays) {
      availDays.push('thursday');
    }
    if (product?.timeConstraint?.availableOnFridays) {
      availDays.push('friday');
    }
    if (product?.timeConstraint?.availableOnSaturdays) {
      availDays.push('saturday');
    }
    if (product?.timeConstraint?.availableOnSundays) {
      availDays.push('sunday');
    }
  }

  if (!hasConstraint || !product.timeConstraint) {
    return (
      null
    );
  }

  return (
    <div className="flex flex-col flex-1 mt-6">
      {(days > 0 || hours > 0 || minutes > 0) && (
       <div>
         <div className="text-gray-500 text-xs">{t('delay_of')}</div>
         <div>
           <FontAwesomeIcon
             icon={faClock}
             className="text-yellow-500 mr-2"
           />
           {days > 0 && (
             <span>{days} {t('days')} </span>
           )}
           {hours > 0 && (
             <span>{hours} hr </span>
           )}
           {minutes > 0 && (
             <span>{minutes} hr </span>
           )}
         </div>
       </div>
      )}
      {availDays.length > 0 && (
        <div>
          <div className="text-gray-500 text-xs">{t('only_available_on')}</div>
          <div>
            {(_.map(availDays, (d) => t(d))).toString().replaceAll(',', ', ')}
          </div>
        </div>
      )}
      {product?.timeConstraint?.limitDate && (
        <div>
          <div className="text-gray-500 text-xs">{t('only_until')}</div>
          <div>
            {toLocalTime(product?.timeConstraint?.limitDate)
                    .format('dddd, D [de] MMMM')}
          </div>
        </div>
      )}
    </div>
  );
}

export default ItemTimeConstraint;
