import React, { useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  addProductsAtom,
  notificationsAtom,
  orderDetailsModalAtom,
  profileOrdersAtom,
  searchAddProductsAtom,
  searchLoadingAtom,
  searchResultsAtom,
  tokenAtom,
} from '../../../../../../../../atoms/Atoms';
import api from '../../../../../../../../api/api';

function ActionButton() {
  const { i18n, t } = useTranslation();
  const token = useRecoilValue(tokenAtom);
  const [order, setOrder] = useRecoilState(addProductsAtom);
  const [showModal, setShowModal] = useRecoilState(orderDetailsModalAtom);
  const [submitting, setSubmitting] = useState(false);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const [orders, setOrders] = useRecoilState(profileOrdersAtom);
  const setSearchResults = useSetRecoilState(searchResultsAtom);
  const setValue = useSetRecoilState(searchAddProductsAtom);
  const setSearchLoading = useSetRecoilState(searchLoadingAtom);

  const addProductsToOrder = () => {
    const mappedItems = _.map(order, (p) => ({
      businessItemId: p.id,
      amount: p.amount,
      price: p.activePrice,
      brand: p.brand,
      name: p.name,
      description: p.description,
      isEBT: p.isEBT,
      itemId: p.id,
      acceptsSubstitute: true,
      hasRandomWeight: p.hasRandomWeight,
      isAlcoholicBeverage: p.isAlcoholicBeverage,
      itemImage: p.imageUrl,
    }));
    setSubmitting(true);
    api
      .post(`order/${showModal.id}/item/add`, mappedItems, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.error) {
          setNotifications([
            ...notifications,
            {
              title: `${t('oops')}`,
              description:
              `${t('add_product_error')}`,
              error: true,
            },
          ]);
          setSubmitting(false);
        } else {
          const subOrders = JSON.parse(JSON.stringify(orders));
          const index = _.findIndex(orders, (o) => o.id === showModal.id);
          subOrders[index] = response.data.data;
          setOrders(subOrders);
          setShowModal(response.data.data);
          setSubmitting(false);
          setSearchResults([]);
          setOrder([]);
          setValue('');
          setSearchLoading(false);
          setNotifications([
            ...notifications,
            {
              title: `${t('yay')}`,
              description:
              `${t('add_product_success')}`,
              error: false,
            },
          ]);
        }
      })
      .catch((error) => {
        setSubmitting(false);
        setNotifications([
          ...notifications,
          {
            title: `${t('oops')}`,
            description: `${t('add_product_error')} - ${error}`,
            error: true,
          },
        ]);
      });
  };

  return (
    <div className="absolute right-4 bottom-4">
      <button
        type="button"
        onClick={() => addProductsToOrder()}
        className="w-52 h-12 flex items-center justify-center space-x-2 ring-2 ring-offset-2 ring-gray-500 text-xs bg-gray-800 hover:bg-gray-700 rounded-lg text-white font-semibold shadow-md tracking-tight uppercase transition duration-500 ease-linear outline-none focus:outline-none"
      >
        {submitting && (
          <div>
            <svg
              className="animate-spin h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              />
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              />
            </svg>
          </div>
        )}
        {!submitting && (
          <>
            <div className="flex items-center justify-center h-6 w-6 text-white bg-accent rounded-full">
              {order && order.length}
            </div>
            <div>{t('add_products')}</div>
          </>
        )}
      </button>
    </div>
  );
}

export default ActionButton;
