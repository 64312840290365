import React, { useEffect, useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AnimatePresence, motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  locationAtom,
  notificationsAtom,
  refreshLocationsAtom,
  tokenAtom,
} from '../../../atoms/Atoms';
import { backdropVariants, modalVariants } from '../../../utils';
import { H2 } from '../typography/Title';
import Subtitle from '../typography/Subtitle';
import Register from './shared-components/register/Register';
import api from '../../../api/api';
import List from './shared-components/list/List';

function Locations() {
  const { i18n, t } = useTranslation();
  const ref = useRef();
  const history = useHistory();
  const token = useRecoilValue(tokenAtom);
  const [location, setLocation] = useRecoilState(locationAtom);
  const refreshLocations = useRecoilValue(refreshLocationsAtom);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);

  useEffect(() => {
    if (location.modal) {
      if (token) {
        setLocation({
          ...location,
          loading: true,
        });

        api
          // We force location to Delivery Location 0n
          .get('user/locations/0n', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            if (response.data.error) {
              setNotifications([
                ...notifications,
                {
                  title: `${t('oops')}`,
                  description:
                  `${t('locations_load_error')}`,
                  error: true,
                  modal: false,
                },
              ]);
            }
            setLocation({
              ...location,
              locations: response.data.data.results,
              loading: false,
            });
          })
          .catch((error) => {
            setNotifications([
              ...notifications,
              {
                title: `${t('oops')}`,
                description: `${t('locations_load_error')} - ${error.message}`,
                error: true,
              },
            ]);
            setLocation({
              ...location,
              loading: false,
            });
          });
      } else {
        setLocation({
          ...location,
          modal: false,
        });
        history.push('/login', { from: 'locations-modal' });
      }
    }
  }, [token, location.modal, refreshLocations]);

  return (
    <AnimatePresence>
      {location.modal && (
        <motion.div
          variants={backdropVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          className="fixed bg-gray-800 inset-0 bg-opacity-75 z-10 flex items-end md:items-center justify-center px-0 md:px-4"
        >
          <motion.div
            variants={modalVariants}
            className="text-gray-800 antialiased justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div
              ref={ref}
              className="flex flex-col bg-white rounded-xl mx-auto w-5/6 h-5/6"
            >
              <header className="flex items-center justify-between space-x-4 rounded-t-xl px-4 py-3">
                <div>
                  <H2 text={t('delivery_location')} />
                  <Subtitle text={t('delivery_warning')} />
                </div>
                <button
                  type="button"
                  onClick={() => setLocation({ ...location, modal: false })}
                  className="hover:opacity-75 outline-none focus:outline-none ring-0 focus:ring-0"
                >
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    className="text-base md:text-xl"
                  />
                </button>
              </header>
              <main className="group relative flex flex-1 overflow-y-auto bg-white rounded-b-xl">
                <section className="flex flex-1 flex-col overflow-y-auto">
                  {/* Tabs Nav */}
                  <div className="sticky top-0 z-50 hidden md:grid grid-cols-2 bg-gray-100 shadow-lg">
                    <button
                      type="button"
                      className={`${
                        location.current === 'locations'
                          ? 'bg-secondary text-white'
                          : 'bg-gray-600 hover:bg-gray-500 text-white'
                      } h-14 uppercase flex items-center justify-center space-x-1 p-4 text-xs font-semibold outline-none focus:outline-none ring-0 focus-ring-0 transition duration-200 ease-linear`}
                      onClick={() => {
                        setLocation({
                          ...location,
                          current: 'locations',
                        });
                      }}
                    >
                      <div>{t('my_locations')}</div>
                      <div className="flex items-center justify-center h-6 w-6 text-white bg-accent rounded-full">
                        {location.locations.length}
                      </div>
                    </button>
                    <button
                      type="button"
                      className={`${
                        location.current === 'register'
                          ? 'bg-secondary text-white'
                          : 'bg-accent text-white'
                      } h-14 uppercase flex items-center justify-center space-x-1 p-4 text-xs font-semibold outline-none focus:outline-none ring-0 focus-ring-0 transition duration-500 ease-linear`}
                      onClick={() => {
                        setLocation({
                          ...location,
                          current: 'register',
                        });
                      }}
                    >
                      <div>{t('register_new_location')}</div>
                    </button>
                  </div>
                  {/* Tabs Content */}
                  {location.current === 'locations' && <List />}
                  {location.current === 'register' && <Register />}
                </section>
              </main>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default Locations;
