import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { motion } from 'framer-motion';
import { matchSorter } from 'match-sorter';
import InfiniteScroll from 'react-infinite-scroller';
import {
  searchBrandAtom,
  searchPageAtom,
  searchStringAtom,
  storeAtom,
  notificationsAtom
} from '../../../atoms/Atoms';
import Card from '../../shared-components/Card';
import Breadcrumb from './shared-components/Breadcrumb';
import Subheader from '../../shared-components/header/shared-components/Subheader';
import Loader from '../../shared-components/loader/Loader';
import envConfig from '../../../envConfig';
import api from '../../../api/api';

function Search() {
  const { t } = useTranslation();
  const searchString = useRecoilValue(searchStringAtom);
  const store = useRecoilValue(storeAtom);
  const [search, setSearch] = useRecoilState(searchPageAtom);
  const [filter, setFilter] = useRecoilState(searchBrandAtom);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
    },
  };

  const getProducts = () => {
    if (!search.loadingMore) {
      setSearch({
        ...search,
        loadingMore: true,
      });
  
      const p = parseInt(search.page, 10) + 1;
      api
        .post(`catalog/search/${store.id}/${p}/${envConfig.SearchItemsPageSize}`, {
          SearchType: 0,
          searchString,
        })
        .then((response) => {
          if (response.data.error) {
            setNotifications([
              ...notifications,
              {
                title: `${t('oops')}`,
                description:
                `${t('search_error')}`,
                error: true,
              },
            ]);
            setSearch({
              ...search,
              loadMore: false,
              loadingMore: false,
            });
          } else {
            const newItems = search.products.concat(response.data.data.items);
            const brands = _.map(
              _.uniqBy(newItems, 'brand'),
              (i) => i.brand
            );
            setSearch({
              ...search,
              page: p,
              loadingMore: false,
              products: newItems,
              brands,
              loadMore: response.data.data.items.length === envConfig.SearchItemsPageSize
            });
          }
        })
        .catch((error) => {
          setSearch({
            ...search,
            loadMore: false,
            loadingMore: false
          });
          setNotifications([
            ...notifications,
            {
              title: `${t('oops')}`,
              description:
              `${t('search_error')}`,
              error: true,
            },
          ]);
        });
    }
  };

  return (
    <>
      <Subheader showSearch showDepartments />
      <motion.div
        initial={{
          y: 100,
          opacity: 0,
          transition: {
            duration: 0.5,
            ease: [0.43, 0.13, 0.23, 0.96],
          },
        }}
        animate={{
          y: 0,
          opacity: 1,
          transition: {
            duration: 0.5,
            ease: [0.43, 0.13, 0.23, 0.96],
          },
        }}
        exit={{
          y: 100,
          opacity: 0,
          transition: {
            duration: 0.5,
            ease: [0.43, 0.13, 0.23, 0.96],
          },
        }}
        className="flex flex-col flex-1 overflow-hidden lg:container lg:mx-auto px-4 lg:px-0"
      >
        <Breadcrumb />
        <div className="flex flex-col flex-1 bg-white rounded-t-xl overflow-y-auto no-scrollbar">
          <div
            className={`${
              search.loading ? 'text-gray-200 animate-pulse' : 'text-gray-900 '
            } flex flex-shrink-0 text-xl font-extrabold tracking-tight truncate border-b p-4`}
          >
            {search.products.length === 0 ? t('search_results') : `${t('search_results')} (${search.products.length})`}
          </div>
          {search.loading && (
            <div className="flex flex-col flex-1 items-center justify-center">
              <Loader color="bg-accent" />
            </div>
          )}
          {!search.loading && search.products.length === 0 && (
            <div className="flex flex-col flex-1 items-center justify-center">           
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 50 }}
                transition={{
                  duration: 3,
                  type: 'spring',
                  stiffness: 260,
                  damping: 20,
                }}
                className="text-center tracking-tight"
              >
                <div className="text-xl font-extrabold">
                  {t('search_no_results')}
                </div>
                <div className="text-sm">
                 {t('search_prompt')}
                </div>
              </motion.div>
            </div>
          )}
          {!search.loading && search.products.length > 0 && (
            <div className="overflow-y-auto flex-col flex-1 p-4">
              {/* {search.brands.length > 1 && (
                <motion.div
                  variants={container}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  className="w-full hidden md:flex flex-wrap
                  bg-grey-100 text-xs space-x-3 justify-center py-2"
                >
                  {_.map(search.brands, (brand) => {
                    if (brand) {
                      return (
                        <motion.button
                          type="button"
                          key={brand}
                          variants={item}
                          whileTap={{
                            scale: 0.8,
                          }}
                          whileHover={{
                            scale: 1.05,
                            transition: {
                              duration: 0.4,
                              type: 'tween',
                              ease: 'easeIn',
                            },
                          }}
                          onClick={() => {
                            if (filter === brand) {
                              setFilter('');
                            } else {
                              setFilter(brand);
                            }
                          }}
                          className={`${
                            filter === brand
                              ? 'mb-3 text-white bg-gray-800 border-0 rounded-full font-medium
                              tracking-tight py-1 px-2 ring-2 ring-gray-500 ring-offset-2
                              outline-none focus:outline-none'
                              : 'mb-3 text-gray-400 bg-gray-100 border rounded-full font-medium
                              tracking-tight py-1 px-2 ring-2 ring-gray-100 ring-offset-2
                              outline-none focus:outline-none'
                          }
                      `}
                        >
                          {brand}
                        </motion.button>
                      );
                    }
                  })}
                </motion.div>
              )} */}
              <InfiniteScroll
                pageStart={0}
                scrollThreshold={0.3}
                loadMore={getProducts}
                hasMore={search.loadMore}
                loader={
                  <div key={0} className="p-4 flex flex-col flex-1 items-center justify-center">
                    <Loader color="bg-accent" />
                  </div>
                }
                useWindow={false}
              >
                <motion.div
                  variants={container}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4"
                >
                  {_.map(
                    filter !== ''
                      ? matchSorter(search.products, filter, {
                          keys: [
                            {
                              minRanking: matchSorter.rankings.EQUAL,
                              key: 'brand',
                            },
                          ],
                        })
                      : search.products,
                    (e) => (
                      <motion.div
                        key={e.id}
                        variants={item}
                        whileHover={{
                          scale: 1.05,
                          transition: {
                            duration: 0.4,
                            type: 'tween',
                            ease: 'easeIn',
                          },
                        }}
                      >
                        <Card
                          name={e.name}
                          description={e.description}
                          image={e.imageUrl || null}
                          id={e.id}
                          brand={e.brand}
                          price={e.activePrice}
                          product={e}
                        />
                      </motion.div>
                    )
                  )}
                </motion.div>
              </InfiniteScroll>
            </div>
          )}
        </div>
      </motion.div>
    </>
  );
}

export default Search;
