import React, { useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DocumentSearchIcon, InformationCircleIcon, TrashIcon } from '@heroicons/react/outline';
import {
  faSearch,
  faSpinner,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';
import { mapOrderStatus, mapOrderType, toLocalTime } from '../../../../../../functions';
import {
  alertAtom,
  chatAtom,
  notificationsAtom,
  orderDetailsModalAtom,
  orderRefreshAtom,
  selectedOrderAtom,
  tokenAtom,
} from '../../../../../../atoms/Atoms';
import { Spinner } from '../../../../../shared-components/Spinner';
import api from '../../../../../../api/api';

dayjs.locale('en');
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

function Order({ order }) {
  const { i18n, t } = useTranslation();
  const token = useRecoilValue(tokenAtom);
  const history = useHistory();
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const setRefreshOrders = useSetRecoilState(orderRefreshAtom);
  const setAlert = useSetRecoilState(alertAtom);
  const setModal = useSetRecoilState(orderDetailsModalAtom);
  const setChat = useSetRecoilState(chatAtom);
  const setSelectedOrder = useSetRecoilState(selectedOrderAtom);
  const [cancelling, setCancelling] = useState(false);
  const [cancellingId, setCancellingId] = useState(null);
  const [loading, setLoading] = useState(false);
  const cancelOrder = (id) => {
    setAlert({
      ...alert,
      loading: true
    });
    setLoading(true);
    setCancelling(true);
    setCancellingId(id);
    api
      .post(`order/cancel/${id}`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        setCancelling(false);
        setCancellingId(null);
        setRefreshOrders(true);
        if (response.data.error) {
          setNotifications([
            ...notifications,
            {
              title: `${t('oops')}`,
              description:
              `${t('order_cancel_error')}`,
              error: true,
            },
          ]);
        } else {
          setNotifications([
            ...notifications,
            {
              title: `${t('yay')}`,
              description: `${t('order_cancel_success')}`,
              error: false,
            },
          ]);
          setAlert({
            show: false,
            loading: false,
            title: '',
            content: '',
            onClick: null,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        setCancelling(false);
        setCancellingId(null);
        setNotifications([
          ...notifications,
          {
            title: `${t('oops')}`,
            description: `${t('order_cancel_error')} - ${error.message}}`,
            error: true,
          },
        ]);
      });
  };

  return (
    <div className="flex items-center justify-between space-x-4 p-4 mx-4 my-1 rounded-lg bg-white border border-red border-opacity-20 hover:border-opacity-30 outline-none">
      <div className="flex w-full">
        <div>
          <div className="text-xs font-semibold tracking-tight text-gray-800">
            {t('order')} #{order && order.confirmationCode}
          </div>
          <div className="text-xs">
            <span>{t('store')}: </span>
            <span className="text-gray-800 font-medium mr-2">
              {order && order?.businessName}
            </span>
          </div>
          <div className="text-xs">
            <span>{t('total')}: </span>
            <span className="text-gray-800 font-medium mr-2">
              ${order && order.orderTotal.toFixed(2)}
            </span>
            <span>
            ({order?.itemCount} {order.itemCount === 1 ? t('item') : t('items')})
            </span>
          </div>
          <div className="text-xs">
            <span>{order && t(mapOrderType(order.type))}: </span>
            <span className="text-gray-800 font-medium">
              {order &&
                toLocalTime(order.endSlotTime)
                .locale(i18n.language)
                .format('dddd, D [de] MMMM, YYYY h:mm A')}
            </span>
          </div>
          <div className="text-xs">
            <span>{t('status')}: </span>{' '}
            <span className={order.status === 9 || order.status === 8 ? 'text-red-600 font-semibold' : 'text-gray-900 font-bold'}>
              {order && t(mapOrderStatus(order.status))}
            </span>
          </div>
        </div>
        {loading && (
        <div className="my-auto ml-auto">
          <Spinner className="inline" />
        </div>)}
      </div>
      <div className="flex items-center space-x-2 justify-end">
        <button
          type="button"   
          className="flex items-center justify-center bg-gray-100 hover:bg-green-200 rounded-full w-10 h-10 ring-0 focus:ring-0 outline-none focus:outline-none transition duration-500 ease-in-out"
          onClick={() => {
            setModal(order);
          }}
        >
          <InformationCircleIcon className="w-5 h-5 text-sky-500" />
        </button>
        <button
          type="button"
          disabled={order && !order.canCancelOrder}   
          className={order && !order.canCancelOrder ? 'hidden' : 'flex items-center justify-center bg-gray-100 hover:bg-red-200 rounded-full w-10 h-10 ring-0 focus:ring-0 outline-none focus:outline-none transition duration-500 ease-in-out'}
          onClick={() => {
            setAlert({
              show: true,
              title: t('cancel_order'),
              content: t('cancel_order_description'),
              onClick: () => cancelOrder(order.id),
            });
          }}
        >
          {cancelling ? <FontAwesomeIcon className="w-4 h-4 text-red-500" icon={faSpinner} spin={cancelling && cancellingId === order.id} /> : <TrashIcon className="w-4 h-4 text-red-500" />}
        </button>
      </div>
      <Menu as="div" className="hidden relative flex justify-end items-center">
        {({ open }) => (
          <>
            <Menu.Button className="w-8 h-8 inline-flex items-center justify-center rounded-md hover:bg-gray-100 outline-none ring-0 focus:outline-none focus:ring-0 transition duration-500 ease-linear">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                />
              </svg>
            </Menu.Button>
            <Transition
              show={open}
              as={React.Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                static
                className="mx-3 origin-top-right absolute right-7 top-0 w-48 mt-1 divide-y divide-gray-100 rounded-xl shadow-lg z-10 bg-white text-xs ring-0 focus:outline-none"
              >
                <Menu.Item>
                  <button
                    type="button"
                    className="rounded-t-xl text-gray-700 group flex items-center space-x-2 p-4 w-full text-left font-medium text-xs tracking-tight hover:bg-gray-100 outline-none focus:outline-none transition duration-500 easy-in-out"
                    onClick={() => {
                      // history.push(`/profile/orders/${order.confirmationCode}`);
                      setModal(order);
                      // console.log(order.confirmationCode);
                      // setSelectedOrder(order.confirmationCode);
                    }}
                  >
                    <FontAwesomeIcon icon={faSearch} />
                    <div>{t('details')}</div>
                  </button>
                </Menu.Item>
                {/* <Menu.Item className="hidden">
                  <button
                    type="button"
                    className="text-gray-700 group flex items-center
                    space-x-2 p-4 w-full text-left font-mediumtext-xs
                    tracking-tight hover:bg-gray-100 outline-none
                    focus:outline-none transition duration-500 easy-in-out"
                    onClick={() => setChat(order)}
                  >
                    <FontAwesomeIcon icon={faCommentAltDots} />
                    <div>Asistencia</div>
                  </button>
                </Menu.Item> */}
                {order.canCancelOrder && (
                  <Menu.Item>
                    <button
                      type="button"
                      disabled={order && !order.canCancelOrder}
                      className="rounded-b-xl text-gray-700 group flex items-center space-x-2 p-4 w-full text-left font-medium text-xs tracking-tight hover:bg-gray-100 outline-none focus:outline-none transition duration-500 easy-in-out"
                      onClick={() => {
                        setAlert({
                          show: true,
                          loading: false,
                          title: t('cancel_order'),
                          content: t('cancel_order_description'),
                          onClick: () => cancelOrder(order.id),
                        });
                      }}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                      <div>{t('cancel')}</div>
                    </button>
                  </Menu.Item>
                )}
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
}

export default Order;
