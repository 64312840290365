import React from 'react';

export function H1({ text }) {
  return (
    <div className="text-lg md:text-xl font-extrabold tracking-tight">
      {text}
    </div>
  );
}

export function H2({ text }) {
  return (
    <div className="text-base md:text-lg font-semibold tracking-tight">
      {text}
    </div>
  );
}

export function H3({ text }) {
  return (
    <div className="text-sm md:text-base font-semibold tracking-tight">
      {text}
    </div>
  );
}
