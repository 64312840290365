import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import ItemsCarousel from 'react-items-carousel';
import { isMobile, isTablet } from 'react-device-detect';
import VisibilitySensor from 'react-visibility-sensor';
import { useMount, useUnmount } from 'react-use';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useParams, NavLink, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRight,
  faArrowLeft,
} from '@fortawesome/free-solid-svg-icons';
import Card from '../../../../shared-components/Card';
import { departmentPageAtom, catalogAtom } from '../../../../../atoms/Atoms';
import useBreakpoint from '../../../../../hooks/useBreakpoint';
import Placeholder from './Placeholder';
import { H1 } from '../../../../shared-components/typography/Title';
import api from '../../../../../api/api';
import Loader from '../../../../shared-components/loader/Loader';
import envConfig from '../../../../../envConfig';

function Carousel({ department, dynamicDepartment }) {
  const { i18n, t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const breakpoint = useBreakpoint();
  const limit = breakpoint;
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [hasLoaded, sethasLoaded] = useState(false);
  const catalog = useRecoilValue(catalogAtom);
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [departmentState, setDepartmentState] = useRecoilState(
    departmentPageAtom
  );
  const [title, setTitle] = useState('');
  const [storeUrl, setStoreUrl] = useState('');
  
  function dynamicDepartmentInfo(dept) {
    let name = '';
    let type = 0;
    if (dept === 'sponsored') {
      name = t('sponsored');
      type = 6;
    } else if (dept === 'myPantry') {
      name = t('buy_again');
      type = 7;
    } else if (dept === 'recentlyAdded') {
      name = t('recently_added');
      type = 9;
    } else if (dept === 'madeLocally') {
      name = t('made_locally');
      type = 10;
    } else if (dept === 'offers') {
      name = t('offers');
      type = 5;
    } else if (dept === 'wishlist') {
      name = t('wishlist');
      type = 4;
    } else if (dept === 'favorites') {
      name = t('favorites');
      type = 3;
    } else if (dept === 'customDepartment01') {
      name = catalog?.customDepartment01Name;
      type = 12;
    } else if (dept === 'customDepartment02') {
      name = catalog?.customDepartment02Name;
      type = 13;
    } else if (dept === 'customDepartment03') {
      name = catalog?.customDepartment03Name;
      type = 14;
    } else if (dept === 'customDepartment04') {
      name = catalog?.customDepartment04Name;
      type = 30;
    } else if (dept === 'customDepartment05') {
      name = catalog?.customDepartment05Name;
      type = 31;
    } else if (dept === 'customDepartment06') {
      name = catalog?.customDepartment06Name;
      type = 32;
    }
    return {
      title: name,
      apiUrl: `catalog/search/${id}`,
      storeUrl: `/stores/${id}/customdepartments/${type}`,
      searchType: type
    };
  }

  let apiUrl = '';
  if (department) {
    apiUrl = `catalog/${id}/department/${department.id}/top/${envConfig.DepartmentCarouselSize}`;
  } else {
    apiUrl = dynamicDepartmentInfo(dynamicDepartment).apiUrl;
  }

  const load = function (isVisible) {
    if (!hasLoaded && isVisible) {
      sethasLoaded(true);
      if (department) {
        api({
          method: 'GET',
          url: `${apiUrl}/0`,
        }).then((response) => {
          if (response.data.data.results.length < envConfig.DepartmentCarouselSize) {
            setHasMore(false);
            setData(response.data.data.results);
          } else {
            setData([...response.data.data.results, 'placeholder']);
          }
        });
      } else {
        const payload = {
          SearchType: dynamicDepartmentInfo(dynamicDepartment).searchType,
        };
        api.post(`${apiUrl}/0/${envConfig.DepartmentCarouselSize}`, payload).then((response) => {
          if (response.data.data.items.length < envConfig.DepartmentCarouselSize) {
            setHasMore(false);
            setData(response.data.data.items);
          } else {
            setData([...response.data.data.items, 'placeholder']);
          }
        });
      }
    }
  };

  useMount(() => {
    if (department) {
      setTitle(department.name);
      setStoreUrl(`/stores/${id}/departments/${department.id}`);
    } else {
      const result = dynamicDepartmentInfo(dynamicDepartment);
      setTitle(result.title);
      setStoreUrl(result.storeUrl);
    }
  });

  useUnmount(() => {
    setData([]);
  });

  const fetchMore = () => {
    if (department) {
      api({
        method: 'GET',
        url: `${apiUrl}/${page}`,
      })
        .then((response) => {
          if (response.data) {
            const filter = _.filter(data, (e) => e !== 'placeholder');
  
            if (response.data.data.results < envConfig.DepartmentCarouselSize) {
              setHasMore(false);
              setData([...filter, ...response.data.data.results]);
            } else {
              setData([...filter, ...response.data.data.results, 'placeholder']);
              setPage(page + 1);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      const payload = {
        SearchType: dynamicDepartmentInfo().searchType,
      };
      api.post(`${apiUrl}/${page}/${envConfig.DepartmentCarouselSize}`, payload).then((response) => {
        if (response.data) {
          const filter = _.filter(data, (e) => e !== 'placeholder');

          if (response.data.data.items < envConfig.DepartmentCarouselSize) {
            setHasMore(false);
            setData([...filter, ...response.data.data.items]);
          } else {
            setData([...filter, ...response.data.data.items, 'placeholder']);
            setPage(page + 1);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
    }
  };

  return (
    <VisibilitySensor offset={{ bottom: -600 }} onChange={(isVisible) => { load(isVisible); }}>
      <div className="space-y-0">
        <div className="container flex flex-col ml-4 space-x-4 px-4">
          <div className="flex items-center font-heading text-2xl tracking-tight text-secondary font-extrabold space-x-2">
            <NavLink
              className="font-medium hover:underline transition duration-500 ease-in-out"
              to={storeUrl}
            >
              <H1 text={title} />
            </NavLink> 
          </div>
          <div className={isMobile ? 'hidden' : 'text-xs'}>
            {/* <span className="font-medium mr-1">
              {t('categories_in_department')}:
            </span> */}
            {department && _.map(department.categories, (e) => (
              <span key={e.id} className="font-medium text-secondary">
                <button
                  type="button"
                  className="hover:underline hover:text-accent hover:font-bold transition duration-500 ease-in-out outline-none focus:outline-none"
                  onClick={() => {
                    history.push(storeUrl);
                    setDepartmentState({
                      ...departmentState,
                      category: e,
                    });
                  }}
                >
                  {e.name}
                </button>
                {_.findIndex(department.categories, (c) => c.id === e.id) !== department.categories.length - 1 && (' | ')}
              </span>
            ))}
            {/* <NavLink
              className="font-medium text-accent hover:underline transition duration-500
              ease-in-out"
              to={storeUrl}
            >
              {t('view_all')}
            </NavLink> */}
          </div>
          <div className={isMobile ? 'text-xs' : 'hidden'}>
            <NavLink
              className="font-medium text-accent hover:underline transition duration-500 ease-in-out"
              to={storeUrl}
            >
              {t('view_all_categories')}
            </NavLink>
          </div>
        </div>
        <ItemsCarousel          
          placeholderItem={<Placeholder />}
          enablePlaceholder
          numberOfPlaceholderItems={limit}
          infiniteLoop={false}
          gutter={12}
          activePosition="right"
          chevronWidth={50}
          disableSwipe={false}
          alwaysShowChevrons={false}
          numberOfCards={limit}
          slidesToScroll={limit}
          showSlither
          firstAndLastGutter
          activeItemIndex={activeItemIndex}
          requestToChangeActive={setActiveItemIndex}
          onActiveStateChange={(state) => {
            if (state.isLastScroll && hasMore) {
              fetchMore();
            }
          }}
          rightChevron={
            <button
              type="button"
              className="flex items-center justify-center h-10 w-10 bg-white shadow rounded-full ring-0 focus:ring-0 outline-none focus:outline-none"
            >
              <FontAwesomeIcon icon={faArrowRight} color="#ED5E5E" />
            </button>
          }
          leftChevron={
            <button
              type="button"
              className="flex items-center justify-center h-10 w-10 bg-white shadow rounded-full ring-0 focus:ring-0 outline-none focus:outline-none"
            >
              <FontAwesomeIcon icon={faArrowLeft} color="#ED5E5E" />
            </button>
          }
          classes={{
            itemsWrapper: 'w-full py-2',
            itemWrapper: 'w-full',
          }}
        >
          {_.map(data, (e) => {
            if (e === 'placeholder') {
              return <Placeholder key={e} />;
            }
  
            return (
              <Card
                key={e.id}
                product={e}
                name={e.name}
                description={e.description}
                image={e.imageUrl || null}
                id={e.id}
                brand={e.brand}
                price={e.activePrice}
              />
            );
          })}
        </ItemsCarousel>
      </div>
    </VisibilitySensor>
  );
}

export default Carousel;
