import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { storeAtom } from '../../../../atoms/Atoms';
import HomeLink from '../../../shared-components/homelink/HomeLink';

function Breadcrumb() {
  const { i18n, t } = useTranslation();
  const store = useRecoilValue(storeAtom);

  return <HomeLink />
  // return (
  //   <div className="flex items-center w-full text-xs tracking-tight py-4 space-x-1 truncate">
  //     <NavLink
  //       to="/"
  //       className="hover:text-accent transition duration-500 ease-in-out"
  //     >
  //       {t('home')}
  //     </NavLink>
  //     <FontAwesomeIcon icon={faChevronRight} />
  //     <NavLink
  //       to="/"
  //       className="hover:text-accent transition duration-500 ease-in-out"
  //     >
  //       {store && store.name}
  //     </NavLink>
  //     <FontAwesomeIcon icon={faChevronRight} />
  //     <div className="text-gray-400">{t('search')}</div>
  //   </div>
  // );
}

export default Breadcrumb;
