import React from 'react';
import _ from 'lodash';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingBasket } from '@fortawesome/free-solid-svg-icons';
import { checkoutSelector } from '../../../../atoms/Selectors';
import Counter from '../../../shared-components/Counter';

function Order() {
  const { i18n, t } = useTranslation();
  const checkout = useRecoilValue(checkoutSelector);

  const getTotal = (id, weightType) => {
    const product = _.filter(checkout.order, (e) => e.id === id && e.weightType === weightType);
    const price = product[0].weightType === 4 ?
    (product[0].activePrice / product[0].unitPriceFactor).toFixed(2) : product[0].activePrice;

    let amount = 0;
    for (let i = 0; i < product.length; i++) {
      amount += product[i].amount;
    }
    return {
      subtotal: price,
      units: amount,
      total: (price * amount).toFixed(2),
    };
  };

  const containerVariants = {
    open: {
      transition: { staggerChildren: 0.07, delayChildren: 0.2 },
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
  };

  const variants = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 },
      },
    },
    closed: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 },
      },
    },
  };

  return (
    <>
      <motion.div
        initial={false}
        animate={checkout.order.length > 0 ? 'open' : 'closed'}
      >
        <motion.div variants={containerVariants} className="mt-4 space-y-2">
          {_.map(
            _.orderBy(_.uniqBy(checkout.order, 'id'), 'name'),
            (e, index) => (
              <motion.div
                variants={variants}
                key={index}
                className="flex space-x-4"
              >
                <div className="flex flex-shrink-0 flex-col items-center justify-center rounded-xl bg-white border space-y-2 p-2">
                  <img alt={e.name} src={e.imageUrl} className="h-16 w-auto" />
                  <Counter product={e} />
                </div>
                <div className="flex flex-col flex-1 text-xs truncate">
                  <div className="w-full truncate text-gray-500 font-light tracking-tight text-xs">
                    {e.brand}
                  </div>
                  <div className="w-full truncate font-bold tracking-tight capitalize">
                    {_.toLower(e.name)}
                  </div>                
                  <div className="w-full truncate text-gray-500 font-light tracking-tight capitalize text-xs">
                    {_.toLower(e.description)}
                  </div>
                  <div className="w-full truncate font-semibold tracking-tight text-xs">
                    ${getTotal(e.id, e.weightType).subtotal} x {getTotal(e.id, e.weightType).units}{' '}
                    {e.hasRandomWeight && e.weightType === 4 &&
                      (getTotal(e.id, e.weightType).units === 1
                        ? `${t('piece')}`
                        : `${t('pieces')}`)}
                    {e.hasRandomWeight && e.weightType !== 4 &&
                      (getTotal(e.id, e.weightType).units === 1
                        ? `${t('pound')}`
                        : `${t('pounds')}`)}
                     {!e.hasRandomWeight &&
                      (getTotal(e.id, e.weightType).units === 1
                        ? `${t('unit')}`
                        : `${t('units')}`)}
                    <div className="font-bold text-md">= ${getTotal(e.id, e.weightType).total}</div>
                  </div>                  
                </div>
              </motion.div>
            )
          )}
        </motion.div>
      </motion.div>
      {checkout.order.length === 0 && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            duration: 1,
            delay: 0.5,
          }}
          className="h-full flex flex-col flex-1 items-center justify-center"
        >
          <FontAwesomeIcon icon={faShoppingBasket} className="text-accent" />
          <div>{t('empty_cart')}</div>
          <div className="text-gray-400 text-xs text-center">
          {t('empty_cart_prompt')}
          </div>
        </motion.div>
      )}
    </>
  );
}

export default Order;
