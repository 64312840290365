import React from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGift } from '@fortawesome/free-solid-svg-icons';
import { loyaltyPointsAtom, userAtom } from '../../atoms/Atoms';
import { formatNumber } from '../../functions';

function LoyaltySummary() {
  const { t } = useTranslation();
  const user = useRecoilValue(userAtom);
  const points = useRecoilValue(loyaltyPointsAtom);

  function share() {
    if (navigator.share) {
      navigator
        .share({
          url: `/register/${user.friendCode}`,
          title: t('referral_link_title'),
          text: t('referral_link_text')
        })
        .then(() => {
          console.log('Sharing successfull');
        })
        .catch(() => {
          console.error('Sharing failed');
        });
    } else {
      console.log('Sorry! Your browser does not support Web Share API');
    }
  }

  return (
    <div className="flex w-full">
      <div className="flex-col">
        <p>
          <FontAwesomeIcon icon={faGift} /> {t('loyalty')}: {formatNumber(points)}
        </p>
      </div>
      <div className="flex-col ml-auto">
        <button
          type="button"
          onClick={() => share()}
          className="text-xs text-accent"
        >
          {t('invite')}
        </button>
      </div>
    </div>
  )
}

export default LoyaltySummary;
