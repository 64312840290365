import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { ArrowNarrowLeftIcon, BellIcon, ClockIcon, CreditCardIcon, CurrencyDollarIcon } from '@heroicons/react/outline';
import { paymentAtom, storeAtom, timeAtom } from '../../../../atoms/Atoms';
import { checkoutSelector, minimumSelector } from '../../../../atoms/Selectors';



function Notification() {
  const { t } = useTranslation();
  const store = useRecoilValue(storeAtom);
  const history = useHistory();
  const checkout = useRecoilValue(checkoutSelector);
  const minimum = useRecoilValue(minimumSelector);
  const time = useRecoilValue(timeAtom);
  const payment = useRecoilValue(paymentAtom);
  const [state, setState] = useState(false);

  useEffect(() => {
    if (time && checkout.hasMinimum && payment.card) {
      setState(false);
    } else {
      setState(true);
    }
  }, [time, checkout.hasMinimum, payment.card]);

  return (
    <Transition
      show={state}
      enter="transition-opacity duration-75"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      className="bg-secondary text-white p-4 rounded-xl mt-4"
    >
      <div className="flex items-center justify-center w-8 h-8 rounded-full bg-accent mb-4">
        <BellIcon className="h-5 w-5 text-white" />
      </div>
      <div className="text-sm mb-2">{t('checkout_requirements_warning')}</div>
      <div className={checkout?.hasMinimum ? 'hidden' : 'text-sm mb-2 flex flex-row'}>
        <CurrencyDollarIcon className="h-5 w-5 mr-2 text-white" />
        <div>
          {t('minimum_prompt')}{' '}
          <span className="text-primary font-bold">
            ${minimum?.toFixed(2)}
          </span>
          , {t('you_have')}{' '}
          <span className="text-primary font-bold">
            ${checkout?.subtotal}
          </span>{' '}
          {t('in_your_cart')}.
        </div>
      </div>
      <div className={time ? 'hidden' : 'text-sm mb-2 flex flex-row'}><ClockIcon className="h-5 w-5 mr-2 text-white" />{t('hours_alert')}</div>
      <div className={payment?.card ? 'hidden' : 'text-sm mb-2 flex flex-row'}><CreditCardIcon className="h-5 w-5 mr-2 text-white" />{t('payment_alert')}</div>
      <button
        type="button"
        onClick={() => history.push(`stores/${store.id}`)}
        className="flex items-center space-x-1 text-xs font-semibold tracking-tight text-amber-400 hover:text-amber-500 ring-0 focus:ring-0 outline-none focus:outline-none transition ease-in-out duration-500"
      >
        <ArrowNarrowLeftIcon className="w-4 h-4 text-primary" />
        <span className="text-white">{t('continue_shopping')}</span>
      </button>
    </Transition>
  );
}

export default Notification;
