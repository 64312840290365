import React, { useState, useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import is from 'is_js';
import { useHistory } from 'react-router-dom';
import { Tab } from '@headlessui/react';
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import Payment from './shared-components/Payment';
import PaymentMethod from './shared-components/PaymentMethod';
import Order from './shared-components/Order';
import { checkoutSelector, minimumSelector } from '../../../atoms/Selectors';
import Hours from './shared-components/Hours';
import PromoCode from './shared-components/PromoCode';
import {
  contactAtom,
  orderAtom,
  notificationsAtom,
  orderSuccess,
  paymentAtom,
  referenceOrderAtom,
  storeAtom,
  successMessageAtom,
  tokenAtom,
  userAtom,
  locationAtom,
  timeAtom,
  serviceAtom,
  isAdditionalItemOrderAtom,
  promoCodeAtom
} from '../../../atoms/Atoms';
import Notification from './shared-components/Notification';
import Confirmation from './shared-components/Confirmation';
import Contact from './shared-components/Contact';
import api from '../../../api/api';
import { H1 } from '../../shared-components/typography/Title';
import Loader from '../../shared-components/loader/Loader';
import ExcludeOrder from './shared-components/ExcludeOrder';
import HomeLink from '../../shared-components/homelink/HomeLink';

function Checkout() {
  const { t } = useTranslation();
  const history = useHistory();
  const checkout = useRecoilValue(checkoutSelector);
  const store = useRecoilValue(storeAtom);
  const contact = useRecoilValue(contactAtom);
  const user = useRecoilValue(userAtom);
  const token = useRecoilValue(tokenAtom);
  const setSuccess = useSetRecoilState(orderSuccess);
  const [promoCode, setPromoCode] = useRecoilState(promoCodeAtom)
  const [payment, setPayment] = useRecoilState(paymentAtom);
  const setTime = useSetRecoilState(timeAtom);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const [referenceOrder, setReferenceOrder] = useRecoilState(
    referenceOrderAtom
  );
  const minimum = useRecoilValue(minimumSelector);
  const [submitting, setSubmitting] = useState(false);
  const setMessage = useSetRecoilState(successMessageAtom);
  const setOrder = useSetRecoilState(orderAtom);
  const [location, setLocation] = useRecoilState(locationAtom);
  const service = useRecoilValue(serviceAtom);
  const isAdditionalItemOrder = useRecoilValue(isAdditionalItemOrderAtom);
  const [needsDeliveryLocation, setNeedsDeliveryLocation] = useState(false);

  const tabs = [
    {
      key: 'included',
      title: checkout.excludedItems.length === 0 ? t('items') : t('items_included'),
      hidden: ''
    },
    {
      key: 'excluded',
      title: t('items_excluded'),
      hidden: checkout.excludedItems.length === 0 ? 'hidden' : '',
    },
  ];

  const sendOrder = () => {
    setSubmitting(true);

    const mappedItems = _.map(checkout.order, (p) => ({
      businessItemId: p.id,
      amount: p.amount,
      price: p.activePrice,
      brand: p.brand,
      name: p.name,
      description: p.description,
      isEBT: p.isEBT,
      itemId: p.id,
      acceptsSubstitute: true,
      hasRandomWeight: p.hasRandomWeight,
      weightType: p.weightType,
      isAlcoholicBeverage: p.isAlcoholicBeverage,
      itemImage: p.imageUrl,
    }));

    const orderObject = {
      OGuid: uuid(),
      AppVersion: '1.0',
      PaymentType: payment.card.last4 ? 0 : payment.card.paymentType,
      OrderLat: null,
      OrderLon: null,
      BusinessId: store.id,
      CardId: payment.card.last4 ? payment.card.id : null,
      PaymentOptionId: payment.card.last4 ? null : payment.card.id,
      DeliveryLocationId: service === 1 ? location?.location?.id : null,
      DeliveryTotal: service === 1 ? checkout.deliveryTotal : 0,
      SubTotal: parseFloat(checkout.subtotal),
      MunicipalTax: parseFloat(checkout.muniTax),
      StateTax: parseFloat(checkout.stateTax),
      TaxTotal: parseFloat(checkout.muniTax) + parseFloat(checkout.stateTax),
      ProcessingFee: referenceOrder?.id ? 0 : checkout.processingFee,
      OrderTotal: parseFloat(checkout.total),
      LocationId: store.locations[0].id,
      PickUpName: `${contact.name} ${contact.phone}`,
      SpecialInstruction: contact.instructions,
      Type: service,
      items: mappedItems,
      DesiredDateTime: checkout.time,
      StartSlotTime: checkout.startSlotTime,
      EndSlotTime: checkout.endSlotTime,
      BypassMinimum: referenceOrder !== null || checkout.hasMinimum,
      IsAdditionalItemOrder: referenceOrder !== null && isAdditionalItemOrder,
      ReferenceOrderId: referenceOrder?.id,
      CouponId: promoCode?.id
    };

    api
      .post('orders', orderObject, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.error) {
          setNotifications([
            ...notifications,
            {
              title: `${t('order_submit_error')}`,
              description: response.data.error,
              error: true,
            },
          ]);
        } else {
          setMessage(response.data);
          setSuccess(true);
          if (referenceOrder) {
            setReferenceOrder(null);
          }
          if (
            orderObject.PaymentOptionId &&
            !payment.card.allowNonFoodProducts &&
            _.filter(checkout.allItems, (product) => !product.isEBT)
          ) {
            setReferenceOrder(response.data.data);
            setOrder(_.filter(checkout.allItems, (product) => !product.isEBT));
          } else {
            setOrder([]);
          }
          localStorage.removeItem('lhf-order');
          const tempPayment = JSON.parse(JSON.stringify(payment));
          tempPayment.card = null;
          localStorage.removeItem('lhf-card');
          localStorage.removeItem('lhf-payment');
          setPayment(tempPayment);
        }
        setSubmitting(false);
      })
      .catch((error) => {
        setNotifications([
          ...notifications,
          {
            title: `${t('order_submit_error')}`,
            description: error.message,
            error: true,
          },
        ]);
        setSubmitting(false);
      });
  };

  useEffect(() => {
    if (service === 1 && location.location === null) {
      setNeedsDeliveryLocation(true);
      setLocation({ ...location, modal: true, preventRedirect: true });
    }
    setReferenceOrder(null);
    setTime(null);
  }, []);

  useEffect(() => {
    if (promoCode) {
      if (promoCode.minimumAmount > checkout.subtotal) {
        setPromoCode(null);
        setNotifications([
          ...notifications,
          {
            title: t('invalid_promo_code_title'),
            description:
              t('invalid_promo_code_minimum').format(promoCode.minimumAmount.toFixed(2)),
            error: true,
          },
        ]);
      } else if (promoCode.appliesTo === 0 && service !== 0) {
        setPromoCode(null);
        setNotifications([
          ...notifications,
          {
            title: t('invalid_promo_code_title'),
            description:
              t('invalid_promo_code_pickup'),
            error: true,
          },
        ]);
      } else if (promoCode.appliesTo === 1 && service !== 1) {
        setPromoCode(null);
        setNotifications([
          ...notifications,
          {
            title: t('invalid_promo_code_title'),
            description:
              t('invalid_promo_code_delivery'),
            error: true,
          },
        ]);
      }
    }
  }, [checkout, service]);

  useEffect(() => {
    if (!location.modal && needsDeliveryLocation && location.location === null) {
      history.push('/');
    }
  }, [location]);

  return (
    <>
      <div className="sticky top-0 bg-white font-heading p-4 border-b border-gray-200 text-right">
        <div className="text-sm capitalize font-semibold">
          {t('your_order')}
        </div>
        <div className="text-xs md:text-sm tracking-tight">
          {t('total')}: ${checkout.total}
        </div>
        {service === 1 && (
          <>
            <div className="text-xs font-normal tracking-tight">
              {t('deliver_to')} {location.location?.name}
            </div>
            <div className="text-xs font-normal tracking-tight">
              {location.location?.addressLine}
            </div>
            <div className="text-xs font-normal tracking-tight">
              {location.location?.city}, {location.location?.zipcode}
            </div>
          </>
        )}
        {service === 0 && (
          <div className="text-xs font-normal tracking-tight">
            {t('pickup_at')} {store?.name}
          </div>
        )}
        {!referenceOrder && !(checkout.subtotal > minimum) && (
          <div className="text-xs font-normal text-accent tracking-tight">
            {`${t('order_minimum')}: $${minimum?.toFixed(2)}`}
          </div>
        )}
      </div>
      <div className="md:flex flex-1 overflow-y-auto md:overflow-y-hidden">
        <PaymentMethod />
        <Confirmation />
        <div className="flex flex-col md:flex-row flex-1">
          <div className="p-4 w-full md:w-1/4 overflow-y-auto flex-shrink-0">
            <Tab.Group>
              <Tab.List className="flex space-x-1 p-2 bg-gray-900 bg-opacity-10 rounded-xl text-xs">
                {_.map(tabs, (e) => (
                  <Tab
                    key={e.key}
                    className={({ selected }) => {
                      if (selected) {
                        return `${e.hidden} bg-secondary text-white hover:ring-2 hover:ring-offset-3 mt-1 rounded w-full py-2 text-center text-xs md:text-md`;
                      }
                      return `${e.hidden} hover:bg-white hover:bg-opacity-50 text-gray-700 hover:ring-2 hover:ring-offset-3 mt-1 rounded w-full py-2 text-center text-xs md:text-md`;
                    }}
                  >
                    {e.title} ({e.key === 'included' ? checkout.order.length : checkout.excludedItems.length})
                  </Tab>
                ))}
              </Tab.List>
              <Tab.Panels className="mt-2">
                <Tab.Panel className="p-4">
                  <Order />
                </Tab.Panel>
                <Tab.Panel className="p-4">
                  <ExcludeOrder />
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
          <motion.div
            initial={{
              x: 50,
              opacity: 0,
              transition: {
                duration: 0.5,
                ease: [0.43, 0.13, 0.23, 0.96],
              },
            }}
            animate={{
              x: 0,
              opacity: 1,
              transition: {
                duration: 0.5,
                ease: [0.43, 0.13, 0.23, 0.96],
              },
            }}
            exit={{
              y: 50,
              opacity: 0,
              transition: {
                duration: 0.5,
                ease: [0.43, 0.13, 0.23, 0.96],
              },
            }}
            className="flex-2 overflow-y-auto w-full md:bg-white border-t border-l md:rounded-tl-xl p-4 space-y-4 md:mt-4"
          >
            <HomeLink />
            <PromoCode />
            <Hours />
            <Contact />
            <Payment />
            <AnimatePresence>
              {is.any.falsy(
                token,
                user,
                payment.card,
                checkout.time,
                checkout.order.length > 0,
                store,
                checkout.hasMinimum
              ) && <Notification />}
            </AnimatePresence>
            <div className="flex items-center grid grid-cols-1 md:grid-cols-2 gap-4 py-4">
              <div className="text-sm text-right md:text-left">
                <div className=" text-gray-400">
                  {t('subtotal')}:{' '}
                  <span className="text-accent font-medium">
                    ${checkout.subtotal}
                  </span>
                </div>
                <div className=" text-gray-400">
                  {t('municipal_tax')}:{' '}
                  <span className="text-accent font-medium">
                    ${checkout.muniTax}
                  </span>
                </div>
                <div className=" text-gray-400">
                  {t('state_tax')}:{' '}
                  <span className="text-accent font-medium">
                    ${checkout.stateTax}
                  </span>
                </div>
                <div className=" text-gray-400">
                  {t('order_service_charge')}:{' '}
                  <span className="text-accent font-medium">
                    ${referenceOrder?.id ? '0.00' : checkout.processingFee?.toFixed(2)}
                  </span>
                </div>
                {service === 1 && (
                  <div className=" text-gray-400">
                    {t('order_delivery_fee')}:{' '}
                    <span className="text-accent font-medium">
                      ${checkout.deliveryTotal.toFixed(2)}
                    </span>
                  </div>
                )}
                {promoCode?.id && (
                  <div className=" text-gray-400">
                    {t('discount')}:{' '}
                    <span className="text-emerald-500 font-medium">
                      ${checkout.discountTotal?.toFixed(2)}
                    </span>
                  </div>
                )}
              </div>
              <button
                type="button"
                disabled={
                  is.any.falsy(
                    token,
                    user,
                    payment.card,
                    checkout.time,
                    checkout.order.length > 0,
                    store,
                    checkout.hasMinimum
                  ) || submitting
                }
                className="flex flex-1 items-center justify-between p-4 hover:shadow-md rounded-xl text-sm font-semibold tracking-tight text-white ring-2 ring-accent ring-offset-2 bg-accent hover:bg-accent disabled:cursor-not-allowed disabled:opacity-50 outline-none focus:outline-none transition-colors duration-500 ease-in-out"
                onClick={() => sendOrder()}
              >
                <motion.div
                  whileHover={{ scale: 1.2, rotate: 360 }}
                  className="text-xs flex items-center justify-center h-6 w-6 bg-secondary rounded-md"
                >
                  {checkout.order.length}
                </motion.div>
                <div className="flex items-center justify-center w-1/2 text-center">
                  {submitting ? (
                    <Loader color="bg-white" />
                  ) : (
                    <span> {t('submit')}</span>
                  )}
                </div>
                <div className="flex items-center space-x-1">
                  <FontAwesomeIcon icon={faDollarSign} className="text-2xl" />
                  <div className="text-xs">{checkout.total}</div>
                </div>
              </button>
            </div>
          </motion.div>
        </div>
      </div>
    </>
  );
}

export default Checkout;
