import React, { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import Panel from '../../../shared-components/panel/Panel';
import { H3 } from '../../../shared-components/typography/Title';
import Subtitle from '../../../shared-components/typography/Subtitle';
import Input from '../../../shared-components/form/Input';
import Button from '../../../shared-components/buttons/Button';
import api from '../../../../api/api';
import {
  promoCodeAtom,
  notificationsAtom,
  storeAtom,
  serviceAtom
} from '../../../../atoms/Atoms';
import { checkoutSelector } from '../../../../atoms/Selectors';

function PromoCode() {
  const { t } = useTranslation();
  const store = useRecoilValue(storeAtom);
  const service = useRecoilValue(serviceAtom);
  const checkout = useRecoilValue(checkoutSelector);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const [promoCode, setPromoCode] = useRecoilState(promoCodeAtom)
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);

  function validateCoupon() {
    setLoading(true);
    api.get(`promocode/validate/${code}/${store.id}`)
    .then((response) => {
      setLoading(false);
      if (response.data.error) {
        setNotifications([
          ...notifications,
          {
            title: t('promo_code_validation_error'),
            description:
              t('promo_code_validation_prompt'),
            error: true,
          },
        ]);
      } else if (response.data.data.isValid) {
        if (response.data.data.minimumAmount > checkout.subtotal) {
          setNotifications([
            ...notifications,
            {
              title: t('invalid_promo_code_title'),
              description:
                t('invalid_promo_code_minimum').format(response.data.data.minimumAmount.toFixed(2)),
              error: true,
            },
          ]);
        } else if (response.data.data.appliesTo === 0 && service !== 0) {
          setNotifications([
            ...notifications,
            {
              title: t('invalid_promo_code_title'),
              description:
                t('invalid_promo_code_pickup'),
              error: true,
            },
          ]);
        } else if (response.data.data.appliesTo === 1 && service !== 1) {
          setNotifications([
            ...notifications,
            {
              title: t('invalid_promo_code_title'),
              description:
                t('invalid_promo_code_delivery'),
              error: true,
            },
          ]);
        } else {
          setPromoCode(response.data.data);
        }
      } else if (response.data.data.isExpired) {
        setNotifications([
          ...notifications,
          {
            title: t('invalid_promo_code_title'),
            description:
              t('invalid_promo_code_expired'),
            error: true,
          },
        ]);
      } else if (response.data.data.isRedeemed) {
        setNotifications([
          ...notifications,
          {
            title: t('invalid_promo_code_title'),
            description:
              t('invalid_promo_code_redeemed'),
            error: true,
          },
        ]);
      } else if (response.data.data.isDifferentStore) {
        setNotifications([
          ...notifications,
          {
            title: t('invalid_promo_code_title'),
            description:
              t('invalid_promo_code_different_store').format(response.data.data.businessName),
            error: true,
          },
        ]);
      } else if (response.data.data.isDifferentUser) {
        setNotifications([
          ...notifications,
          {
            title: t('invalid_promo_code_title'),
            description:
              t('invalid_promo_code_different_user'),
            error: true,
          },
        ]);
      } else {
        setNotifications([
          ...notifications,
          {
            title: t('invalid_promo_code_title'),
            description:
              t('invalid_promo_code'),
            error: true,
          },
        ]);
      }
    })
    .catch((err) => {
      setLoading(false);
      setNotifications([
        ...notifications,
        {
          title: t('promo_code_validation_error'),
          description: err.message,
          error: true,
        },
      ]);
    });
  }

  return (
    <div hidden>
      <Panel>
        <H3 text={t('promo_code_title')} />
        <Subtitle
          text={t('promo_code_description')}
        />
        {promoCode ? (
          <>
            <div className="flex py-2">
              <div className="border rounded-lg p-2">
                <p className={`font-bold ${(promoCode.minimumAmount > 0) ? '' : 'mt-1'}`}>{promoCode.code}</p>
                {promoCode && (promoCode?.minimumAmount > 0) && (
                  <p>{t('minimum_purchase').format(promoCode?.minimumAmount?.toFixed(2))}</p>
                )}
              </div>
              <Button
                text={t('remove')}
                textColor="text-white"
                onClick={(e) => setPromoCode(null)}
                className="bg-rose-500 hover:bg-rose-600 w-20 mt-3 ml-3"
              />
            </div>
          </>
        ) : (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-xs py-4 w-full">
              <Input
                name="code"
                type="text"
                placeholder=""
                value={code}
                uppercase
                onChange={(e) => setCode(e.target.value)}
                className="col-span-2"
              />
            </div>
            <Button
              text={t('apply')}
              textColor="text-white"
              disabled={loading || code?.length < 1}
              loading={loading}
              onClick={(e) => validateCoupon()}
              loaderColor="bg-emerald-300"
              className="bg-emerald-500 hover:bg-emerald-400 w-20"
            />
          </>
        )}
      </Panel>
    </div>
  );
}

export default PromoCode;
