import React, {
  useState,
  useEffect
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { usePopper } from 'react-popper';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import {
  departmentsAtom,
  catalogAtom,
  departmentAtom,
  departmentPageAtom,
  storeAtom
} from '../../../atoms/Atoms';

function Navigation({ showDepartments, showCategories }) {
  const { t } = useTranslation();
  const store = useRecoilValue(storeAtom);
  const [departments, setDepartments] = useRecoilState(departmentsAtom);
  const catalog = useRecoilValue(catalogAtom);
  const setDepartment = useSetRecoilState(departmentAtom);
  const [state, setState] = useRecoilState(departmentPageAtom);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);

  const [selectedDepartmentLocal, setSelectedDepartmentLocal] = useState(null);

  const history = useHistory();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: 'arrow', options: { element: arrowElement } }],
  });

  const setVisible = function (dId, visibility) {
    const temp = JSON.parse(JSON.stringify(departments));
    for (let i = 0; i < temp.length; i++) {
      if (temp[i].id === dId) {
        temp[i].visible = visibility;
      } else {
        temp[i].visible = false;
      }
    }
    setDepartments(temp);
  };

  useEffect(() => {
    if (state?.department) {
      setSelectedDepartmentLocal(state?.department);
    } else {
      setSelectedDepartmentLocal(null);
    }
  }, [state]);

  return (
    <div className="flex flex-row mx-auto space-x-2 p-4 h-14 text-white">
      {showDepartments && (
        <div className="hidden lg:flex">
          {_.map(departments, (d) => (
            <div
              onMouseEnter={() => {
                setVisible(d.id, true);
              }}
              onMouseLeave={() => {
                setVisible(d.id, false);
              }}
              className="relative"
            >
              <div
                ref={setReferenceElement}
                className="text-sm font-medium cursor-pointer p-2 underline"
              >
                <button
                  type="button"
                  key={d.id}
                  className="text-left text-xs hover:text-accent transition duration-500 easy-in-out outline-none focus:outline-none"
                  onClick={() => {
                    setVisible(false);
                    history.push(`/stores/${store.id}/departments/${d.id}`);
                  }}
                >
                  {d.name}
                </button>
              </div>
              <Transition
                as={React.Fragment}
                show={d.visible !== undefined ? d.visible : false}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <div
                  className="flex flex-col space-y-2 absolute z-10 mt-8 origin-top-right bg-white shadow-lg p-4"
                  ref={setPopperElement}
                  style={styles.popper}
                  {...attributes.popper}
                >
                  {_.map(d.categories, (c) => (
                    <button
                      type="button"
                      key={c.id}
                      className="text-left text-xs text-secondary w-max hover:text-accent transition duration-500 easy-in-out outline-none focus:outline-none"
                      onClick={() => {
                        setVisible(false);
                        setDepartment(d);
                        history.push(`/stores/${store.id}/departments/${d.id}`);
                        setState({
                          ...state,
                          category: c,
                        });
                      }}
                    >
                      {c.name}
                    </button>
                  ))}
                </div>
              </Transition>
            </div>
          ))}
        </div>
      )}
      <div className="flex items-center lg:hidden">
        {showDepartments && (
          <Menu>
            <div className="relative">
              <Menu.Button className="flex items-center text-left text-sm hover:text-accent transition duration-500 easy-in-out outline-none focus:outline-none">
                <div className="truncate">
                  {selectedDepartmentLocal ? selectedDepartmentLocal.name : t('select_department')}
                </div>
                <ChevronDownIcon className="w-4 h-full text-primary" />
              </Menu.Button>
              <Menu.Items className="text-xs divide-y absolute z-50 transform -translate-x-1/2 left-full mt-2 h-auto w-60 no-scrollbar overflow-y-auto bg-white divide-y divide-gray-100 rounded-xl shadow-lg outline-none focus:outline-none">
                {_.map(departments, (d) => (
                  <Menu.Item
                    as="div"
                    className="p-4 ring-0 outline-none focus:ring-0 focus:outline-none"
                  >
                    <button
                      type="button"
                      className={`hover:text-accent text-secondary outline-none focus:outline-none font-semibold ${selectedDepartmentLocal?.id === d.id ? 'text-accent' : ''}`}
                      onClick={() => {
                        setSelectedDepartmentLocal(d);
                        history.push(`/stores/${store.id}/departments/${d.id}`);
                      }}
                    >
                      {d.name}
                    </button>
                  </Menu.Item>
                ))}
              </Menu.Items>
            </div>
          </Menu>
        )}
        {showDepartments && showCategories && (
          <div
            className="border-r-2 border-gray-400 h-6 mx-3"
          />
        )}
        <button
          type="button"
          className="flex text-left text-sm transition duration-500 easy-in-out outline-none focus:outline-none"
        >
          {state?.department && showCategories && (
            <Menu>
              <div className="relative">
                <Menu.Button className="flex items-center text-left text-sm hover:text-accent transition duration-500 easy-in-out outline-none focus:outline-none">
                  <div className="truncate">{state?.category?.name}</div>
                  <ChevronDownIcon className="w-4 h-full text-primary" />
                </Menu.Button>
                <Menu.Items className="text-xs divide-y absolute z-50 transform -translate-x-1/2 left-full mt-2 h-auto w-60 no-scrollbar overflow-y-auto bg-white divide-y divide-gray-100 rounded-xl shadow-lg outline-none focus:outline-none">
                  {_.map(selectedDepartmentLocal?.categories, (c) => (
                    <Menu.Item
                      as="div"
                      className="p-4 ring-0 text-secondary outline-none focus:ring-0 focus:outline-none"
                    >
                      <button
                        type="button"
                        className="outline-none focus:outline-none font-semibold hover:text-accent"
                        onClick={() => {
                          if (!state?.department) {
                            setDepartment(selectedDepartmentLocal);
                            history.push(`/stores/${store.id}/departments/${selectedDepartmentLocal.id}`);
                            setState({
                              ...state,
                              category: c,
                              department: selectedDepartmentLocal,
                            });
                          } else {
                            setSelectedDepartmentLocal(state?.department);
                            setState({
                              category: c,
                              department: state?.department,
                              SearchType: 2,
                              sort: 0,
                              page: 0,
                              hasMore: true,
                              Filters: [],
                              products: [],
                              loading: true,
                            });
                          }
                        }}
                      >
                        {c.name}
                      </button>
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </div>
            </Menu>
          )}
        </button>
      </div>
    </div>
  )
}

export default Navigation;
