import React, { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { orderAtom } from '../../atoms/Atoms';

function Counter({ product, className }) {
  const { i18n, t } = useTranslation();
  const [count, setCount] = useState(0);
  const [order, setOrder] = useRecoilState(orderAtom);

  useEffect(() => {
    if (order.length > 0) {
      const filter = _.filter(order, (p) => p.id === product.id);
      let amount = 0;
      for (let i = 0; i < filter.length; i++) {
        amount += filter[0].amount;
      }
      setCount(amount);
    } else {
      setCount(0);
    }
  }, [order]);

  if (count === 0 && product.isAvailable) {
    return (
      <button
        type="button"
        disabled={!product.isAvailable}
        onClick={(e) => {
          e.stopPropagation();
          const tempProduct = JSON.parse(JSON.stringify(product));
          if (tempProduct.hasRandomWeight) {
            tempProduct.amount = product.minQuantity;
            setOrder([...order, { ...tempProduct, uuid: uuidv4() }]);
          } else {
            let newOrder = order;
            for (let i = 0; i < tempProduct.minQuantity; i++) {
              tempProduct.uuid = uuidv4();
              tempProduct.amount = 1;
              newOrder = newOrder.concat(tempProduct);
            }
            setOrder(newOrder);
          }
        }}
        className={`${className} w-24 h-7 text-xs rounded-2xl font-semibold text-white bg-primary disabled:bg-gray-300 disabled:cursor-not-allowed hover:bg-accent transition duration-500 ease-in-out outline-none focus:outline-none`}
      >
        {product.isAvailable && (
          <FontAwesomeIcon icon={faPlus} />
        )}
        {!product.isAvailable && `${t('not_available')}`}
      </button>
    );
  }
  if (!product.isAvailable) {
    return (
      <button
        type="button"
        disabled={!product.isAvailable}
        className={`${className} w-24 h-7 text-xs rounded-2xl font-semibold text-white bg-accent disabled:cursor-not-allowed hover:bg-accent transition duration-500 ease-in-out outline-none focus:outline-none`}
      >
      {product.isAvailable && (
        <FontAwesomeIcon icon={faPlus} />
      )}
      {!product.isAvailable && `${t('not_available')}`}
      </button>
    )
  }
  return (
    <div className={`${className} w-24 flex items-center rounded-2xl`}>
      <button
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          if (count !== 0) {
            let newOrder = JSON.parse(JSON.stringify(order));
            if (product.hasRandomWeight) {
              const index = _.findIndex(order, (o) => o.id === product.id);
              if (newOrder[index].amount - product.stepAmount < product.minQuantity) {
                newOrder = _.filter(order, (p, i) => i !== index);
              } else {
                newOrder[index].amount -= product.stepAmount;
              }
            } else {
              const tempAmount = _.filter(order, (p) => p.id === product.id).length;
              if (tempAmount - 1 < product.minQuantity) {
                newOrder = _.filter(order, (p, i) => p.id !== product.id);
              } else {
                const index = _.findIndex(order, (o) => o.id === product.id);
                newOrder = _.filter(order, (p, i) => i !== index);
              }
            }
            setOrder(newOrder);
          }
        }}
        className="bg-white w-7 h-7 border rounded-l-2xl font-semibold text-gray-500 hover:text-gray-400 disabled:cursor-not-allowed transition duration-500 ease-in-out outline-none focus:outline-none"
      >
        -
      </button>
      <div className="bg-white text-xs flex items-center justify-center font-extrabold tracking-tight w-10 h-7 border-t border-b">
        {count}{product.hasRandomWeight && product.weightType === 0 ? ' lb' : ''}{product.hasRandomWeight && product.weightType === 4 ? ' pc' : ''}
      </div>
      <button
        type="button"
        disabled={!product.isAvailable}
        onClick={async (e) => {
          e.stopPropagation();
          const tempProduct = JSON.parse(JSON.stringify(product));
          const index = _.findIndex(order, (o) => o.id === product.id);
          if (index > -1) {
            if (tempProduct.hasRandomWeight) {
              if (order[index].amount + tempProduct.stepAmount <= tempProduct.maxQuantity) {
                const newOrder = JSON.parse(JSON.stringify(order));
                newOrder[index].amount += tempProduct.stepAmount;
                setOrder(newOrder);
              }
            } else {
              const tempAmount = _.filter(order, (p) => p.id === product.id).length;
              if (tempAmount + 1 <= tempProduct.maxQuantity) {
                tempProduct.amount = 1;
                setOrder([...order, { ...tempProduct, uuid: uuidv4() }]);
              }
            }
          }
        }}
        className="bg-white w-7 h-7 border rounded-r-2xl font-semibold text-gray-500 hover:text-gray-400 disabled:cursor-not-allowed transition duration-500 ease-in-out outline-none focus:outline-none"
      >
        +
      </button>
    </div>
  );
}

export default Counter;
