import React, { useState } from 'react';
import { motion } from 'framer-motion';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faPencilAlt,
  faStar,
} from '@fortawesome/free-solid-svg-icons';
import { useRecoilState, useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  locationAtom,
  orderAtom,
  serviceAtom,
  storeAtom,
} from '../../../../../../atoms/Atoms';
import { businessesSelector } from '../../../../../../atoms/Selectors';
import Button from '../../../../buttons/Button';
import Loader from '../../../../loader/Loader';
import api from '../../../../../../api/api';

function SmallDevicesList() {
  const { t } = useTranslation();
  const history = useHistory();
  const stores = useRecoilValueLoadable(businessesSelector);
  const [locations, setLocations] = useRecoilState(locationAtom);
  const [store, setStore] = useRecoilState(storeAtom);
  const [order, setOrder] = useRecoilState(orderAtom);
  const setService = useSetRecoilState(serviceAtom);
  const [state, setState] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleAlert = async (e) => {
    await setStore(e);
    await setService(1);
    await setOrder([]);
    await setLocations({
      ...locations,
      alert: null,
      page: 0,
      modal: false,
      location: state,
    });
    await localStorage.setItem('lhf-store', JSON.stringify(e));
    await localStorage.setItem('lhf-service', '1');
    await localStorage.removeItem('lhf-order');
    await localStorage.setItem('lhf-location', JSON.stringify(state));
    if (!locations.preventRedirect) {
      await history.push(`/stores/${e.id}`);
    }
  };

  if (locations.loading || loading) {
    return (
      <div className="flex flex-col flex-1 items-center justify-center">
        <Loader color="bg-gray-700" />
      </div>
    );
  }

  return (
    <motion.div
      variants={{
        hidden: {
          opacity: 0,
        },
        show: {
          opacity: 1,
          transition: {
            staggerChildren: 0.01,
          },
        },
      }}
      initial="hidden"
      animate="show"
      className="group relative flex flex-col md:hidden overflow-hidden"
    >
      <button
        type="button"
        onClick={() => setLocations({ ...locations, current: 'register' })}
        className="absolute z-10 bottom-4 right-4 text-white font-semibold text-xs bg-accent hover:bg-accent h-12 w-12 rounded-full shadow-xl outline-none focus:outline-none ring-0 focus:ring-0"
      >
        <FontAwesomeIcon icon={faPencilAlt} className="text-xl" />
      </button>
      <div className="flex items-center justify-between h-16 px-4 text-white bg-gray-600">
        <motion.div
          initial={{ x: 10 }}
          animate={{ x: 0 }}
          exit={{ x: 10 }}
          className="font-medium text-xs"
        >
          {t('my_locations')}
        </motion.div>
        <motion.div
          initial={{ opacity: 0, scale: 0 }}
          animate={{
            opacity: 1,
            scale: 1,
            transition: {
              delay: 1,
            },
          }}
          exit={{ opacity: 0, scale: 0 }}
          className="text-xs font-medium flex items-center justify-center h-6 w-6 text-white bg-accent rounded-full"
        >
          {locations.locations.length}
        </motion.div>
      </div>
      <div className="flex-1 overflow-auto divide-y divide-gray-100">
        {_.map(locations.locations, (l) => (
          <motion.button
            type="button"
            variants={{
              hidden: { x: '100%', opacity: 0 },
              show: { x: 0, opacity: 1 },
            }}
            key={l.id}
            disabled={!l.isDeliveryAvailable}
            whileTap={{ x: -5 }}
            className="h-16 flex items-center text-left w-full p-4 space-x-4 transition duration-500 ease-in-out"
            onClick={async () => {
              await setState(l);
              const deliveryStore = _.filter(stores.contents.data.data.results, (b) => b.id === 'a1')[0];
              if (store && order.length > 0 && store.id !== deliveryStore.id) {
                await setLoading(true);
                const items = _.map(order, (o) => o.id);
                const response = await api.post(`cart/switch/${deliveryStore.id}`, items);
                if (!response.data.error) {
                  const newOrderItems = [];
                  for (let i = 0; i < order.length; i++) {
                    const filtered = _.filter(response.data.data.items,
                      (it) => it?.parentItemId === order[i]?.parentItemId);
                    if (filtered.length > 0) {
                      const found = {
                        ...filtered[0],
                        amount: order[i].amount,
                        isEBT: filtered[0].isEbt
                      };
                      newOrderItems.push(found);
                    }
                  }
                  await setOrder(newOrderItems);
                } else {
                  await setOrder([]);
                }
                await setLoading(false);
              }
              await setStore(deliveryStore);
              await setService(1);
              await setLocations({
                ...locations,
                modal: false,
                page: 0,
                location: l,
              });
              await localStorage.setItem('lhf-service', '1');
              await localStorage.setItem(
                'lhf-location',
                JSON.stringify(l)
              );
              await localStorage.setItem('lhf-store', JSON.stringify(deliveryStore));
              if (!locations.preventRedirect) {
                await history.push(`/stores/${deliveryStore.id}`);
              }
            }}
          >
            <FontAwesomeIcon
              icon={faStar}
              className={
                state?.id === l.id ? 'text-amber-400' : 'text-gray-300'
              }
            />
            <div className="flex-1 text-xs truncate">
              <div className={`truncate font-medium ${l.isDeliveryAvailable ? '' : 'text-gray-400'}`}>{l.name}</div>
              <div className={`truncate ${l.isDeliveryAvailable ? 'text-gray-400' : 'text-gray-300'}`}>{l.addressLine}</div>
            </div>
            <div className="text-xs text-red-400" hidden={l.isDeliveryAvailable}>
              {t('delivery_not_available')}
            </div>
            <FontAwesomeIcon
              hidden={!l.isDeliveryAvailable}
              icon={faChevronRight}
              className="text-xs text-gray-400"
            />
          </motion.button>
        ))}
      </div>
      {locations.alert && (
      <motion.div
        initial={{
          y: '100%',
        }}
        animate={{
          y: 0,
          transition: {
            delay: 0.5,
          },
        }}
        exit={{
          y: '100%',
        }}
        className="inset-x-0 absolute z-10 flex flex-col items-center justify-center text-center bottom-0 bg-gray-900 bg-opacity-95 h-1/2 text-white p-4 rounded-br-xl"
      >
        <div className="font-semibold mb-2">¡Oopss!</div>
        <div className="text-xs">
          {t('change_store_prompt')}
        </div>
        <div className="space-x-2 mt-4">
          <Button
            text={t('cancel')}
            onClick={() => {
              setLocations({ ...locations, alert: null });
            }}
            className="bg-gray-800 w-20 border border-gray-700 hover:border-gray-600"
          />
          <Button
            text={t('accept')}
            onClick={() => handleAlert(locations.alert)}
            className="bg-accent hover:bg-accent w-20"
          />
        </div>
      </motion.div>
    )}
    </motion.div>
  );
}

export default SmallDevicesList;
