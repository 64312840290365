import React, { useState } from 'react';
import {
  useRecoilState,
  useSetRecoilState,
  useRecoilValueLoadable
} from 'recoil';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faStar,
} from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import {
  locationAtom,
  orderAtom,
  serviceAtom,
  storeAtom
} from '../../../../../../atoms/Atoms';
import { businessesSelector } from '../../../../../../atoms/Selectors';
import EmptyLocations from '../../../../illustrations/EmptyLocations';
import { H2 } from '../../../../typography/Title';
import Subtitle from '../../../../typography/Subtitle';
import Button from '../../../../buttons/Button';
import Loader from '../../../../loader/Loader';
import api from '../../../../../../api/api';

function MediumDevicesList() {
  const { t } = useTranslation();
  const stores = useRecoilValueLoadable(businessesSelector);
  const history = useHistory();
  const setService = useSetRecoilState(serviceAtom);
  const [order, setOrder] = useRecoilState(orderAtom);
  const [locations, setLocations] = useRecoilState(locationAtom);
  const [store, setStore] = useRecoilState(storeAtom);
  const [state, setState] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleAlert = async (e) => {
    await setStore(e);
    await setService(1);
    await setOrder([]);
    await setLocations({
      ...locations,
      alert: null,
      page: 0,
      modal: false,
      location: state,
    });
    await localStorage.setItem('lhf-store', JSON.stringify(e));
    await localStorage.setItem('lhf-location', JSON.stringify(state));
    await localStorage.setItem('lhf-service', '1');
    await localStorage.removeItem('lhf-order');
    if (!locations.preventRedirect) {
      await history.push(`/stores/${e.id}`);
    }
  };

  if (locations.loading || loading) {
    return (
      <div className="flex flex-col flex-1 items-center justify-center">
        <Loader color="bg-gray-700" />
      </div>
    );
  }

  if (!locations.loading && locations.locations.length === 0) {
    return (
      <div className="flex flex-col flex-1 items-center justify-center">
        <EmptyLocations className="w-64 h-44" />
        <div className="text-center p-4">
          <H2 text="Sin Localidades" />
          <Subtitle text="Aún no tienes localidades registradas en tu perfil. Comienza registrando una localidad para seleccionar la tienda mas cercana a ti." />
        </div>
        <Button
          text="Registrar"
          textColor="text-white"
          className="bg-accent hover:bg-accent w-24"
          onClick={() => setLocations({ ...locations, current: 'register' })}
        />
      </div>
    );
  }

  return (
    <div className="w-full grid grid-cols-1 divide-x divide-gray-100 text-sm overflow-hidden">
      <motion.div
        variants={{
          hide: {
            opacity: 0,
          },
          show: {
            opacity: 1,
            transition: {
              staggerChildren: 0.1,
            },
          },
        }}
        initial="hide"
        animate="show"
        className="flex flex-col flex-1 overflow-y-auto divide-y divide-gray-100"
      >
        {_.map(locations.locations, (l) => (
          <motion.button
            type="button"
            disabled={!l.isDeliveryAvailable}
            key={l.id}
            variants={{
              hide: { opacity: 0 },
              show: {
                opacity: 1,
              },
            }}
            className={`${state?.id === l.id && 'sticky top-0 shadow-md'}
              hover:bg-gray-50 h-16 flex items-center text-left w-full p-4 space-x-4 transition duration-500 ease-in-out bg-white
            `}
            onClick={async () => {
              await setState(l);
              const deliveryStore = _.filter(stores.contents.data.data.results, (b) => b.id === 'a1')[0];
              if (store && order.length > 0 && store.id !== deliveryStore.id) {
                await setLoading(true);
                const items = _.map(order, (o) => o.id);
                const response = await api.post(`cart/switch/${deliveryStore.id}`, items);
                if (!response.data.error) {
                  const newOrderItems = [];
                  for (let i = 0; i < order.length; i++) {
                    const filtered = _.filter(response.data.data.items,
                      (it) => it?.parentItemId === order[i]?.parentItemId);
                    if (filtered.length > 0) {
                      const found = {
                        ...filtered[0],
                        amount: order[i].amount,
                        isEBT: filtered[0].isEbt
                      };
                      newOrderItems.push(found);
                    }
                  }
                  await setOrder(newOrderItems);
                } else {
                  await setOrder([]);
                }
                await setLoading(false);
              }
              await setStore(deliveryStore);
              await setService(1);
              await setLocations({
                ...locations,
                modal: false,
                page: 0,
                location: l,
              });
              await localStorage.setItem('lhf-service', '1');
              await localStorage.setItem(
                'lhf-location',
                JSON.stringify(l)
              );
              await localStorage.setItem('lhf-store', JSON.stringify(deliveryStore));
              if (!locations.preventRedirect) {
                await history.push(`/stores/${deliveryStore.id}`);
              }
            }}
          >
            <FontAwesomeIcon
              icon={faStar}
              className={
                state?.id === l.id ? 'text-amber-400' : 'text-gray-300'
              }
            />
            <div className="flex-1 text-xs truncate">
              <div className={`truncate font-medium ${l.isDeliveryAvailable ? '' : 'text-gray-400'}`}>{l.name}</div>
              <div className={`truncate ${l.isDeliveryAvailable ? 'text-gray-400' : 'text-gray-300'}`}>{l.addressLine}</div>
            </div>
            <div className="text-xs text-red-400 bg-transparent" hidden={l.isDeliveryAvailable}>
              {t('delivery_not_available')}
            </div>
            <FontAwesomeIcon
              hidden={!l.isDeliveryAvailable}
              icon={faChevronRight}
              className="text-xs text-gray-400"
            />
          </motion.button>
        ))}
        {locations.alert && (
          <motion.div
            initial={{
              y: '100%',
            }}
            animate={{
              y: 0,
              transition: {
                delay: 0.5,
              },
            }}
            exit={{
              y: '100%',
            }}
            className="inset-x-0 absolute z-10 flex flex-col items-center justify-center text-center bottom-0 bg-gray-900 bg-opacity-95 h-1/2 text-white p-4 rounded-br-xl"
          >
            <div className="font-semibold mb-2">¡Oopss!</div>
            <div className="text-xs">
              {t('change_store_prompt')}
            </div>
            <div className="space-x-2 mt-4">
              <Button
                text={t('cancel')}
                onClick={() => {
                  setLocations({ ...locations, alert: null });
                }}
                className="bg-gray-800 w-20 border border-gray-700 hover:border-gray-600"
              />
              <Button
                text={t('accept')}
                onClick={() => handleAlert(locations.alert)}
                className="bg-accent hover:bg-accent w-20"
              />
            </div>
          </motion.div>
        )}
      </motion.div>
    </div>
  );
}

export default MediumDevicesList;
