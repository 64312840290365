import { useState, useEffect } from 'react';
import _ from 'lodash';

const getDeviceConfig = (width) => {
  if (width < 440) {
    return 1;
  }
  if (width >= 440 && width < 640) {
    return 2;
  }
  if (width >= 640 && width < 768) {
    return 3;
  }
  if (width >= 768 && width < 1024) {
    return 3;
  }
  if (width >= 1024 && width < 1280) {
    return 4;
  }
  if (width >= 1280 && width < 1920) {
    return 5;
  }
  if (width >= 1920) {
    return 6;
  }
};

const useBreakpoint = () => {
  const [brkPnt, setBrkPnt] = useState(() =>
    getDeviceConfig(window.innerWidth)
  );

  useEffect(() => {
    const calcInnerWidth = _.throttle(() => {
      setBrkPnt(getDeviceConfig(window.innerWidth));
    }, 200);
    window.addEventListener('resize', calcInnerWidth);
    return () => window.removeEventListener('resize', calcInnerWidth);
  }, []);

  return brkPnt;
};
export default useBreakpoint;
