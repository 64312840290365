import { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import visa from './assets/img/credit_cards/visa.svg';
import amex from './assets/img/credit_cards/amex.svg';
import diners from './assets/img/credit_cards/diners.svg';
import mastercard from './assets/img/credit_cards/mastercard.svg';
import discover from './assets/img/credit_cards/discover.svg';
import unknown from './assets/img/credit_cards/unknown.svg';


export const toLocalTime = (date) => {
  if (date) {
    return dayjs(date).subtract(4, 'hour');
  }
  return dayjs.utc().subtract(4, 'hour')
};

export const getType = (card) => {
  switch (card) {
    case 'visa':
      return visa;
    case 'amex':
      return amex;
    case 'american express':
      return amex;
    case 'mastercard':
      return mastercard;
    case 'discover':
      return discover;
    case 'diners':
      return diners;
    default:
      return unknown;
  }
};

export const mapPaymentType = (type) => {
  switch (type) {
    case 0:
      return 'Stripe';
    case 1:
      return 'ATH Movil';
    case 7:
      return 'ATH';
    case 3:
      return 'Other';
    case 4:
      return 'EBT';
    case 5:
      return 'Paypal';
    case 10:
      return 'MCS';
    case 11:
      return 'MMM';
    case 16:
      return 'SSS';
    case 17:
      return 'Humana';
    default:
      return type;
  }
};

export const mapOrderType = (type) => {
  switch (type) {
    case 0:
      return 'pickup';
    case 1:
      return 'delivery';
    case 6:
      return 'deli_pickup';
    case 7:
      return 'deli_delivery';
    case 5:
      return 'self_checkout';
    default:
      return 'undefined';
  }
};

export const mapTaxRule = (rule) => {
  switch (rule) {
    case 0:
      return 'None';
    case 1:
      return 'Municipal Tax Only';
    case 6:
      return 'State Tax Only';
    case 7:
      return 'State and Municipal Tax';
    default:
      return 'undefined';
  }
};

export const mapOrderStatus = (status) => {
  switch (status) {
    case 0:
      return 'status_placed';
    case 2:
      return 'status_delivery_assigned';
    case 3:
      return 'status_accepted';
    case 6:
      return 'status_customer_picked_up';
    case 7:
      return 'status_delivered';
    case 8:
      return 'status_store_cancelled';
    case 9:
      return 'status_user_cancelled';
    case 10:
      return 'status_declined';
    case 5:
      return 'status_delivery_started';
    case 16:
      return 'status_picking_started';
    case 17:
      return 'status_picking_finished';
    case 18:
      return 'status_packing_started';
    case 20:
      return 'status_ready_for_pickup';
    case 21:
      return 'status_pending_delivery_assignment';
    case 27:
      return 'status_pending_paypal_payment';
    case 30:
      return 'status_pending_payment';
    case 32:
      return 'status_under_review';
    case 33:
      return 'status_ivu_control_pending';
    default:
      return 'status_unknown';
  }
};

export const mapWeightTypeAbbr = (weightType) => {
  switch (weightType) {
    case 0:
      return 'lb.';
    case 1:
      return 'oz.';
    case 2:
      return 'foz.';
    case 3:
      return 'gr.';
    default:
      return 'each_abbr';
  }
}

export const mapWeightType = (weightType) => {
  switch (weightType) {
    case 0:
      return 'pounds';
    case 1:
      return 'ounces';
    case 2:
      return 'fluid_ounces';
    case 3:
      return 'grams';
    default:
      return 'unit';
  }
}

export function useHorizontalScroll() {
  const elRef = useRef();
  useEffect(() => {
    const el = elRef.current;
    if (el) {
      const onWheel = (e) => {
        if (e.deltaY === 0) return;
        e.preventDefault();
        el.scrollTo({
          left: el.scrollLeft + e.deltaY,
          behavior: 'smooth'
        });
      };
      el.addEventListener('wheel', onWheel);
      return () => el.removeEventListener('wheel', onWheel);
    }
  }, []);
  return elRef;
}

export function formatNumber(number) {
  if (number) {
    return number.toLocaleString();
  }
  return '';
}
