import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { faPlus, faPlusCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import {
  faCcAmex,
  faCcDinersClub,
  faCcDiscover,
  faCcMastercard,
  faCcVisa,
} from '@fortawesome/free-brands-svg-icons';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AnimatePresence, motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import {
  alertAtom,
  notificationsAtom,
  paymentAtom,
  serviceAtom,
  storeAtom,
  tokenAtom,
} from '../../../../atoms/Atoms';
import api from '../../../../api/api';
import { getType } from '../../../../functions';
import Panel from '../../../shared-components/panel/Panel';
import { H3 } from '../../../shared-components/typography/Title';
import Subtitle from '../../../shared-components/typography/Subtitle';
import Description from '../../../shared-components/typography/Description';
import { Spinner } from '../../../shared-components/Spinner';

function Payment() {
  const { i18n, t } = useTranslation();
  const token = useRecoilValue(tokenAtom);
  const store = useRecoilValue(storeAtom);
  const service = useRecoilValue(serviceAtom);
  const [payment, setPayment] = useRecoilState(paymentAtom);
  const [alert, setAlert] = useRecoilState(alertAtom);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const fetchPayment = () => {
    api
      .get(`store/paymentoptions/${store.locations[0].id}/${service}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.error) {
          setPayment({
            ...payment,
            loading: false,
            error: response.data.error,
          });
        } else {
          const tempOptions = JSON.parse(JSON.stringify(response.data.data.paymentOptions));
          for (let i = 0; i < tempOptions.length; i++) {
            tempOptions[i].load = false;
          }
          setPayment({
            ...payment,
            loading: false,
            error: null,
            cards: response.data.data.userCards,
            options: [
              ...response.data.data.userCards,
              ...tempOptions,
            ],
            card: window.localStorage.getItem('lhf-card'),
          });
        }
      })
      .catch((error) => {
        setPayment({
          ...payment,
          loading: false,
          error: error.message,
        });
      });
  };

  const deletePayment = (id) => {
    api
      .post(`user/cards/remove/${id}`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.error) {
          setNotifications([
            ...notifications,
            {
              title: `${t('oops')}`,
              description:
                `${t('card_eliminated_error')}`,
              error: true,
            },
          ]);
        } else {
          setNotifications([
            ...notifications,
            {
              title: `${t('yay')}`,
              description:
              `${t('card_eliminated_success')}`,
              error: false,
            },
          ]);
          fetchPayment();
        }
      })
      .catch((error) => {
        setNotifications([
          ...notifications,
          {
            title: `${t('oops')}`,
            description: `${t('card_eliminated_error')} - ${error.message}`,
            error: true,
          },
        ]);
      });
  };

  const createBillingAgreement = (option) => {
    const selectedOption = JSON.parse(JSON.stringify(option));
    const temp = JSON.parse(JSON.stringify(payment));
    for (let i = 0; i < temp.options.length; i++) {
      if (temp.options[i].id === option.id) {
        temp.options[i].load = true;
      }
    }
    setPayment(temp);
    api
      .post(`billingAgreement/create/${option.id}`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const temp2 = JSON.parse(JSON.stringify(payment));
        for (let k = 0; k < temp2.options.length; k++) {
          temp2.options[k].load = false;
        }
        if (response.data.error) {
          setPayment({
            ...payment,
            options: temp2.options,
            loading: false,
            error: response.data.error,
            card: undefined,
          });
        } else if (response?.data?.data?.length > 0) {
          setPayment({
            ...payment,
            options: temp2.options,
            loading: false,
          });
          const newWindow = window.open(
            response.data.data,
            '_blank',
            'noopener,noreferrer'
          );
          if (newWindow) newWindow.opener = null;
        } else {
          setPayment({
            ...payment,
            card: selectedOption
          });
          localStorage.setItem('lhf-payment', JSON.stringify(selectedOption));
        }
      })
      .catch((error) => {
        setPayment({
          ...payment,
          options: temp.options,
          loading: false,
          error: error.message,
          card: undefined,
        });
      });
  };

  useEffect(() => {
    if (store && token) {
      fetchPayment();
    }
  }, [token, store, service]);

  useEffect(() => {
    if (store && token) {
      fetchPayment();
    }
  }, []);

  useEffect(() => {
    if (payment.options) {
      const option = localStorage.getItem('lhf-payment');
      if (payment) {
        setPayment({
          ...payment,
          card: JSON.parse(option),
        });
      }
    }
  }, [payment.options]);

  return (
    <Panel>
      <H3 text={t('payment_method')} />
      <div className="text-xs flex flex-row">      
          <FontAwesomeIcon icon={faPlusCircle} className="text-accent mt-1" />      
          <button
            type="button"
            onClick={() => {
              setPayment({ ...payment, modal: true });
            }}
            className="ml-1 font-medium text-accent hover:opacity-2 transition duration-500 ease-in-out"
          >
            {t('payment_add_description')}
          </button>
      </div>
      <AnimatePresence>
        <div className="grid grid-col-1 md:grid-cols-2 lg:grid-cols-3 gap-4 py-4">
          <motion.button
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.8 }}
            transition={{ duration: 0.5 }}
            type="button"
            className="h-20 bg-gray-100 flex items-center justify-center rounded-xl p-2 outline-none focus:outline-none"
            onClick={() => {
              setPayment({ ...payment, modal: true });
            }}
          >
            <div className="text-xs tracking-tight">
              <div className="font-semibold">
                {t('add_new_card')}
              </div>
              <div className="flex items-center justify-center space-x-1 text-2xl mt-1 opacity-75">
                <FontAwesomeIcon icon={faCcVisa} />
                <FontAwesomeIcon icon={faCcMastercard} />
                <FontAwesomeIcon icon={faCcAmex} />
                <FontAwesomeIcon icon={faCcDinersClub} />
                <FontAwesomeIcon icon={faCcDiscover} />
              </div>
            </div>
          </motion.button>
          {payment.options &&
            _.map(payment.options, (option, index) => (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.5 }}
                tabIndex={-1}
                role="button"
                key={`option-${index}`}
                onClick={() => {
                  if (
                    option.needsBillingAgreement &&
                    !option.hasActiveBillingAgreement
                  ) {
                    createBillingAgreement(option);
                  } else {
                    setPayment({
                      ...payment,
                      card: option,
                    });
                    localStorage.setItem('lhf-payment', JSON.stringify(option));
                  }
                }}
                className="text-xs tracking-tight text-left h-20 flex flex-col py-2 bg-white border rounded-xl outline-none focus:outline-none"
              >
                <div className="px-2 flex items-center justify-between w-full font-bold">
                  <div>
                    <span>{option.name || option.nickname}</span>
                    {option.expirationDate && (
                      <span className="text-gray-500 font-light ml-1">
                        (Exp. {option.expirationDate})
                      </span>
                    )}
                  </div>
                  <div className="h-4 w-4 flex items-center justify-center bg-gray-200 ring-2 ring-gray-300 ring-offset-2 rounded-full">
                    {payment.card && _.isEqual(payment.card, option) && (
                      <div className="bg-accent h-2 w-2 rounded-full" />
                    )}
                  </div>
                </div>
                <div className="px-2 text-gray-500">
                  {option?.specialInstruction}
                </div>
                <div className="w-full px-2 flex items-center justify-between space-x-1 mt-1">
                  <img
                    alt={option?.name}
                    src={
                      (option.imageUrl && option.imageUrl) ||
                      (option.paymentImage && option.paymentImage) ||
                      getType(_.toLower(option.brand))
                    }
                    className="h-6 w-auto"
                  />
                  {option.nickname && (
                    <button
                      type="button"
                      className="text-sm mt-4 text-accent h-4 w-4 flex items-center justify-center outline-none focus:outline-none"
                      onClick={(e) => {
                        e.stopPropagation();
                        setAlert({
                          show: true,
                          title: t('delete_payment_title'),
                          content:
                            t('delete_payment_content'),
                          onClick: async () => {
                            await deletePayment(option.id);
                            await setAlert({
                              ...alert,
                              show: false,
                            });
                          },
                        });
                      }}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                  )}
                  {!option.nickname && (
                    <div className="m-2">
                      <Spinner spinning={option.load} />
                    </div>
                  )}
                </div>
              </motion.div>
            ))}
          {payment.loading &&
            _.times(5, (e) => (
              <motion.div
                initial={false}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                key={e}
                className="h-20 flex space-x-2 bg-white border border-gray-100 rounded-xl p-2 transition duration-500 ease-in-out outline-none focus:outline-none"
              >
                <div className="w-full space-y-2">
                  <div className="h-2 w-1/3 bg-gray-100 rounded-full animate-pulse" />
                  <div className="h-2 w-2/3 bg-gray-100 rounded-full animate-pulse" />
                  <div className="h-6 w-10 bg-gray-100 rounded mt-1 animate-pulse" />
                </div>
              </motion.div>
            ))}
        </div>
      </AnimatePresence>
      {payment.cards && payment.cards.length > 0 && (
        <div className="text-xs">
            {t('edit_card_prompt')}:
          <Link
            to="/profile/payment"
            className="ml-1 font-medium text-accent hover:opacity-50 transition duration-500 ease-in-out"
          >
            {t('edit_cards')}
          </Link>
        </div>
      )}
      <Description text={`*${t('note')}: ${t('payment_note')}`} />
    </Panel>
  );
}

export default Payment;
