import React from 'react';

function Terms() {  
  return (
    <div className="overflow-y-auto mx-auto mt-12 justify-center">
     <h3 className="font-heading font-extrabold text-3xl text-center">Terms of Use</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-32 mt-4 md:mt-12 mx-6 md:mx-64 mb-12">
        <div className="space-y-5">
          <div>
            <h1 className="text-md font-semibold">PRIVACY STATEMENT</h1>
            <p className="text-sm">At La Hacienda Meat Center, we respect your privacy. 
              We have developed our Privacy Policy, which is available to you on this Site,
              to inform you of our practices and policies regarding the collection, use,
              maintenance and disclosure of your personal information.
            </p>
          </div>
          <div>
            <h1 className="text-md font-semibold">
              USER OF THE SITE; USER CONDUCT
            </h1>          
            <p className="text-sm">These Terms of Service govern your use of the Site.
              You may use the Site, and the information, images and/or other materials
              contained on the Site (collectively, the “Content”), solely for personal,
              non-commercial purposes. No right, title or interest in any Content is
              transferred to you. La Hacienda Meat Center reserves complete title
              and full intellectual property rights in the Site and all Content.
              You may not use, alter, copy, distribute, transmit or derive another
              work from any Content obtained from the Site. You agree to use the 
              Site only for lawful purposes. You agree not to take any action
              that might compromise the security of the Site, render the Site
              inaccessible to others or otherwise cause damage to the Site
              or the Content. You agree not to add to, subtract from, or
              otherwise modify the Content, or to attempt to access any
              Content that is not intended for you. You agree not to use 
              the Site in any manner that might interfere with the rights
              of third parties.
            </p>
          </div>
          <div>
            <h1 className="text-md font-semibold">
              CONTENT
            </h1>
            <p className="text-sm">
              The Content may contain typographical errors or other errors or inaccuracies
              and may not be complete or current. We reserve the right to correct
              any errors, inaccuracies or omissions and to change or update the
              Content at any time without prior notice. Nevertheless, we make
              no guarantee that any errors, inaccuracies or omissions will be corrected.
            </p>
          </div>
          <div>
            <h1 className="text-md font-semibold">
              INTELLECTUAL PROPERTY
            </h1>
            <p className="text-sm">
              The Site and the Content are protected by U.S. and/or foreign copyright 
              laws, and belong to La Hacienda Meat Center. You may not manipulate or
              alter in any way images or other Content on the Site. You are prohibited
              from using any of the trademarks or logos appearing throughout the Site,
              except as permitted by applicable law.
            </p>
          </div>
        </div>
        <div className="space-y-5">
          <div>
            <h1 className="text-md font-semibold">
              DOWNLOADS
            </h1>
            <p>
              La Hacienda Meat Center does not guarantee or warrant that files available
              for downloading through the Site will be free of infection by software viruses
              or other harmful computer code, files or programs.
            </p>
          </div>
          <div>
            <h1 className="text-md font-semibold">
              LINKS
            </h1>
            <p className="text-sm">
              Links on the Site to third party web sites or information are
              provided solely as a convenience to you. If you use these links,
              you will leave the Site. Such links do not constitute or imply an
              endorsement, sponsorship or recommendation by La Hacienda Meat Center
              of the third party, the third-party website or the information
              contained therein. La Hacienda Meat Center is not responsible
              for the availability of any such websites. La Hacienda Meat
              Center is not responsible or liable for any such website
              or the content thereon. If you use the links to the web
              sites of La Hacienda Meat Center affiliates or service providers,
              you will leave the Site, and will be subject to the terms 
              of use and privacy policy applicable to those web sites.
            </p>
          </div>
          <div>
            <h1 className="text-md font-semibold">
              SOFTWARE
            </h1>
            <p className="text-sm">
              Any software available for download via the Site is the copyrighted work of
              La hacienda Meat Center and/or its licensors. Use of such software is
              governed by the terms of the end user license agreement that
              accompanies or is included with the software. Downloading, 
              installing, and/or using any such software indicates your acceptance 
              of the terms of the end user license agreement.
            </p>
          </div>
        </div>
      </div>  
    </div>
  );
}

export default Terms;
