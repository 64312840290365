import React from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { locationAtom } from '../../../../../atoms/Atoms';
import EmptyLocations from '../../../illustrations/EmptyLocations';
import { H2 } from '../../../typography/Title';
import Subtitle from '../../../typography/Subtitle';
import Button from '../../../buttons/Button';
import Loader from '../../../loader/Loader';
import SmallDevicesList from './shared-components/SmallDevicesList';
import MediumDevicesList from './shared-components/MediumDevicesList';
import useBreakpoint from '../../../../../hooks/useBreakpoint';


function List() {
  const { i18n, t } = useTranslation();
  const [location, setLocation] = useRecoilState(locationAtom);
  const breakpoint = useBreakpoint();

  if (location.loading) {
    return (
      <motion.div className="flex flex-col flex-1 items-center justify-center">
        <Loader color="bg-gray-700" />
      </motion.div>
    );
  }

  if (!location.loading && location.locations.length === 0) {
    return (
      <div className="flex flex-col flex-1 items-center justify-center">
        <EmptyLocations className="w-64 h-44" />
        <div className="text-center p-4">
          <H2 text={t('no_locations')} />
          <Subtitle text={t('no_locations_prompt')} />
        </div>
        <Button
          text={t('Register')}
          textColor="text-white"
          className="bg-accent hover:bg-accent w-24"
          onClick={() => setLocation({ ...location, current: 'register' })}
        />
      </div>
    );
  }

  return (
    <div className="flex flex-1 text-sm overflow-hidden">
      {breakpoint < 3 ? <SmallDevicesList /> : <MediumDevicesList />}
    </div>
  );
}

export default List;
