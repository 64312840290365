import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Transition, Disclosure, Dialog } from '@headlessui/react';
import {
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
  useResetRecoilState
} from 'recoil';
import {
  faAppleAlt,
  faCaretRight,
  faCog,
  faCreditCard,
  faHome,
  faKey,
  faLocationArrow,
  faReceipt,
  faSignInAlt,
  faStore,
  faTimesCircle,
  faUserPlus,
} from '@fortawesome/free-solid-svg-icons';
import { NavLink, useHistory } from 'react-router-dom';
import {
  locationAtom,
  menuAtom,
  paymentAtom,
  storeAtom,
  tokenAtom,
  userAtom,
} from '../../../atoms/Atoms';
import Logo from '../../../assets/img/LaHaciendaLogo.png';
import Leaf from '../../../assets/img/lhfleaf.png';
import User from '../user/User';
import { phoneNumber } from '../../../utils';
import LoyaltySummary from '../LoyaltySummary';
import { businessesSelector } from '../../../atoms/Selectors';

function Navigator() {
  const { i18n, t } = useTranslation();
  const history = useHistory();
  const refreshStore = useResetRecoilState(businessesSelector);
  const store = useRecoilValue(storeAtom);
  const setToken = useSetRecoilState(tokenAtom);
  const setPayment = useSetRecoilState(paymentAtom);
  const [sidebar, setSidebar] = useRecoilState(menuAtom);
  const [location, setLocation] = useRecoilState(locationAtom);
  const [user, setUser] = useRecoilState(userAtom);

  return (
    <Transition.Root show={sidebar} as={React.Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 overflow-hidden"
        open={sidebar}
        onClose={setSidebar}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0 bg-gray-800 bg-opacity-75" />
          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <Transition.Child
              as={React.Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-md">
                <div className="h-full flex flex-col bg-white overflow-hidden">
                  <div className="px-4 h-20 flex items-center justify-between bg-primary">
                    <Dialog.Title className="flex flex-row text-lg font-medium text-gray-900">
                      <img
                        alt="leaf"
                        src={Leaf}
                        className="w-8 md:w-8 h-auto"
                      />
                      <img
                        alt="logo"
                        src={Logo}
                        className="w-32 md:w-48 h-auto"
                      />
                    </Dialog.Title>
                    <div className="flex items-center">
                      <button
                        type="button"
                        className="text-md text-white hover:text-white outline-none focus:outline-none ring-0 focus:ring-0"
                        onClick={() => setSidebar(false)}
                      >
                        <span className="sr-only">Close panel</span>
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </button>
                    </div>
                  </div>
                  {user && (
                    <div className="px-4 py-4 bg-secondary">
                      <User />
                    </div>
                  )}
                  <nav className="flex flex-col flex-1 text-secondary overflow-auto divide-y divide-gray-100">
                    {user && (
                      <NavLink
                        exact
                        to="/profile/loyalty"
                        activeClassName="bg-gray-100"
                        onClick={() => setSidebar(false)}
                        className="hidden px-4 py-3 flex items-center space-x-2 text-xs tracking-tight transition-colors duration-200 ease-linear hover:bg-gray-100 ring-0 focus:ring-0 outline-none focus:outline-none"
                      >
                        <LoyaltySummary />
                      </NavLink>
                    )}
                    <NavLink
                      exact
                      to="/"
                      activeClassName="bg-gray-100"
                      onClick={() => setSidebar(false)}
                      className="px-4 py-3 flex items-center space-x-2 text-xs tracking-tight transition-colors duration-200 ease-linear hover:bg-gray-100 ring-0 focus:ring-0 outline-none focus:outline-none"
                    >
                      <FontAwesomeIcon icon={faHome} />
                      <div>{t('home')}</div>
                    </NavLink>
                    {store && (
                      <NavLink
                        exact
                        to={`/stores/${store && store.id}`}
                        activeClassName="bg-gray-100"
                        onClick={() => setSidebar(false)}
                        className="px-4 py-3 flex items-center space-x-2 text-xs tracking-tight transition-colors duration-200 ease-linear hover:bg-gray-100 ring-0 focus:ring-0 outline-none focus:outline-none"
                      >
                        <FontAwesomeIcon icon={faStore} />
                        <div> {store.name}</div>
                      </NavLink>
                    )}
                    {store && store?.locations && (
                      <NavLink
                        exact
                        to={`/stores/${store && store.id}/departments`}
                        activeClassName="bg-gray-100"
                        onClick={() => setSidebar(false)}
                        className="px-4 py-3 flex items-center space-x-2 text-xs tracking-tight transition-colors duration-200 ease-linear hover:bg-gray-100 ring-0 focus:ring-0 outline-none focus:outline-none"
                      >
                        <FontAwesomeIcon icon={faAppleAlt} />
                        <div>{t('departments')}</div>
                      </NavLink>
                    )}
                    {user && (
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="px-4 py-3 flex items-center space-x-2 text-xs tracking-tight transition-colors duration-200 ease-linear hover:bg-gray-100 ring-0 focus:ring-0 outline-none focus:outline-none">
                              <FontAwesomeIcon
                                icon={faCaretRight}
                                className={`${
                                  open
                                    ? 'text-gray-700 rotate-90'
                                    : 'text-gray-700'
                                } text-base flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150`}
                              />
                              <div>{t('my_account')}</div>
                            </Disclosure.Button>
                            <Transition
                              show={open}
                              enter="transition ease-in-out duration-200"
                              enterFrom="transform -translate-y-1 opacity-0"
                              enterTo="transform translate-y-0 opacity-00"
                              leave="transition ease-in-out duration-200"
                              leaveFrom="transform translate-y-0 opacity-100"
                              leaveTo="transform -translate-y-1 opacity-0"
                            >
                              <Disclosure.Panel
                                static
                                className="border-b border-gray-100 bg-white divide-y divide-gray-100 ring-0 focus:ring-0 outline-none focus:outline-none"
                              >
                                <NavLink
                                  to="/profile/orders"
                                  activeClassName="bg-gray-100"
                                  onClick={() => setSidebar(false)}
                                  className="pl-10 pr-4 py-3 flex items-center space-x-2 text-xs tracking-tight transition-colors duration-200 ease-linear hover:bg-gray-100 ring-0 focus:ring-0 outline-none focus:outline-none"
                                >
                                  <FontAwesomeIcon icon={faReceipt} />
                                  <div>{t('orders')}</div>
                                </NavLink>
                                <NavLink
                                  to="/profile/settings"
                                  activeClassName="bg-gray-100"
                                  onClick={() => setSidebar(false)}
                                  className="pl-10 pr-4 py-3  flex items-center space-x-2 text-xs tracking-tight transition-colors duration-200 ease-linear hover:bg-gray-100 ring-0 focus:ring-0 outline-none focus:outline-none"
                                >
                                  <FontAwesomeIcon icon={faCog} />
                                  <div>{t('profile')}</div>
                                </NavLink>
                                <NavLink
                                  to="/profile/password"
                                  activeClassName="bg-gray-100"
                                  onClick={() => setSidebar(false)}
                                  className="pl-10 pr-4 py-3 flex items-center space-x-2 text-xs tracking-tight transition-colors duration-200 ease-linear hover:bg-gray-100 ring-0 focus:ring-0 outline-none focus:outline-none"
                                >
                                  <FontAwesomeIcon icon={faKey} />
                                  <div>{t('password')}</div>
                                </NavLink>
                                <NavLink
                                  to="/profile/payment"
                                  activeClassName="bg-gray-100"
                                  onClick={() => setSidebar(false)}
                                  className="pl-10 pr-4 py-3 flex items-center space-x-2 text-xs tracking-tight transition-colors duration-200 ease-linear hover:bg-gray-100 ring-0 focus:ring-0 outline-none focus:outline-none"
                                >
                                  <FontAwesomeIcon icon={faCreditCard} />
                                  <div>{t('payment_methods')}</div>
                                </NavLink>
                                <NavLink
                                  to="/profile/locations"
                                  activeClassName="bg-gray-100"
                                  onClick={() => setSidebar(false)}
                                  className="pl-10 pr-4 py-3 flex items-center space-x-2 text-xs tracking-tight transition-colors duration-200 ease-linear hover:bg-gray-100 ring-0 focus:ring-0 outline-none focus:outline-none"
                                >
                                  <FontAwesomeIcon icon={faLocationArrow} />
                                  <div>{t('my_locations')}</div>
                                </NavLink>
                              </Disclosure.Panel>
                            </Transition>
                          </>
                        )}
                      </Disclosure>
                    )}
                    {location?.location && (
                      <Disclosure
                        activeClassName="bg-gray-100"
                        className="truncate px-4 py-3 flex items-center space-x-2 text-xs tracking-tight transition-colors duration-200 ease-linear hover:bg-gray-100 ring-0 focus:ring-0 outline-none focus:outline-none"
                      >
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="px-4 py-3 flex items-center space-x-2 text-xs tracking-tight transition-colors duration-200 ease-linear hover:bg-gray-100 ring-0 focus:ring-0 outline-none focus:outline-none">
                              <FontAwesomeIcon
                                icon={faCaretRight}
                                className={`${
                                  open
                                    ? 'text-gray-700 rotate-90'
                                    : 'text-gray-700'
                                } text-base flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150`}
                              />
                              <div>{t('selected_location')}</div>
                            </Disclosure.Button>
                            <Transition
                              show={open}
                              enter="transition ease-in-out duration-200"
                              enterFrom="transform -translate-y-1 opacity-0"
                              enterTo="transform translate-y-0 opacity-00"
                              leave="transition ease-in-out duration-200"
                              leaveFrom="transform translate-y-0 opacity-100"
                              leaveTo="transform -translate-y-1 opacity-0"
                            >
                              <Disclosure.Panel
                                static
                                className="border-b border-gray-100 bg-white divide-y divide-gray-100 ring-0 focus:ring-0 outline-none focus:outline-none"
                              >
                                <div className="pl-10 pr-4 py-3 flex flex-1 items-center space-x-2 text-xs text-gray-400 tracking-tight ring-0 focus:ring-0 outline-none focus:outline-none">
                                 {location.location.name}: {location.location.addressLine}
                                </div>
                                <NavLink
                                  to="/"
                                  onClick={() => {
                                    setLocation({ ...location, modal: true });
                                    setSidebar(false);
                                  }}
                                  className="pl-10 pr-4 py-3 flex items-center space-x-2 text-xs tracking-tight transition-colors duration-200 ease-linear hover:bg-gray-100 ring-0 focus:ring-0 outline-none focus:outline-none"
                                >
                                  <FontAwesomeIcon icon={faLocationArrow} />
                                  <div>{t('change_address')}</div>
                                </NavLink>
                              </Disclosure.Panel>
                            </Transition>
                          </>
                        )}
                      </Disclosure>
                    )}
                    {!user && (
                      <>
                        <NavLink
                          to="/login"
                          activeClassName="bg-gray-100"
                          onClick={() => setSidebar(false)}
                          className="px-4 py-3 flex items-center space-x-2 text-xs tracking-tight transition-colors duration-200 ease-linear hover:bg-gray-100"
                        >
                          <FontAwesomeIcon icon={faSignInAlt} />
                          <div>{t('signin')}</div>
                        </NavLink>
                        <NavLink
                          to="/register"
                          activeClassName="bg-gray-100"
                          onClick={() => setSidebar(false)}
                          className="space-x-2 px-4 py-3 flex items-center space-x-2 text-xs tracking-tight transition-colors duration-200 ease-linear hover:bg-gray-100"
                        >
                          <FontAwesomeIcon icon={faUserPlus} />
                          <div>{t('register')}</div>
                        </NavLink>
                      </>
                    )}
                  </nav>
                  {store && store?.locations && (
                    <div className="text-xs p-4 bg-gray-100">
                      <div className="font-semibold">{t('contact')}</div>
                      <div className="text-gray-400">
                        {store.locations[0].name}
                      </div>
                      <div className="text-gray-400">
                        {store.locations[0].address1}.{' '}
                        {store.locations[0].address2}
                      </div>
                      <div className="text-gray-400">
                      {`${t('Phone')}`}: {phoneNumber(store.locations[0].orderPhone)}
                      </div>
                      <div className="text-gray-400">
                        E-mail: {store.locations[0].orderEmail}
                      </div>
                    </div>
                  )}
                  {user && (
                    <div className="flex items-center justify-between px-4 h-16 bg-accent hover:bg-opacity-50 transition duration-200 ease-in-out">
                      <button
                        type="button"
                        className="text-xs font-medium text-white h-full w-full ring-0 outline-none focus:ring-0 focus:outline-none"
                        onClick={() => {
                          setUser(null);
                          setToken(null);
                          setPayment({
                            card: null,
                            cards: null,
                            loading: true,
                            error: null,
                            options: null,
                          });
                          refreshStore();
                          window.localStorage.removeItem('lhf-user');
                          window.localStorage.removeItem('lhf-card');
                          window.localStorage.removeItem('lhf-atoken');
                          window.localStorage.removeItem('lhf-state');
                          setSidebar(false);
                          history.push('/login');
                        }}
                      >
                        {t('signout')}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default Navigator;
