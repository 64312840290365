import React from 'react';
import Items from './shared-components/Items';

function Locations() {
  return (
    <div className="flex flex-col flex-1 px-4 overflow-auto mb-4">
      <Items />
    </div>
  );
}

export default Locations;
