import React from 'react';

function Placeholder() {
  return (
    <div className="h-64 md:h-72 min-h-64 md:min-h-72 flex flex-col justify-center items-center bg-white rounded-xl border p-4">
      <div className="bg-gray-100 h-32 w-32 mb-2 rounded-md animate-pulse" />
      <div className="bg-gray-100 h-2 w-10 rounded-full mx-auto mb-1 animate-pulse" />
      <div className="bg-gray-100 h-2 w-full rounded-full mx-auto mb-1 animate-pulse px-4" />
      <div className="bg-gray-100 h-2 w-16 rounded-full mx-auto mb-1 animate-pulse" />
      <div className="bg-gray-100 h-2 w-10 rounded-full mx-auto mb-1 animate-pulse" />
    </div>
  );
}

export default Placeholder;
