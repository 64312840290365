import React, { useState } from 'react';
import _ from 'lodash';
import Counter from './Counter';

function Card({ name, image, price, description, brand, className, product }) {
  const [valid, setValid] = useState(true);

  return (
    <div
      className={`
        ${className} h-72 flex flex-col items-center p-4 flex-shrink-0 flex-1 bg-white rounded-xl w-full border border-amber-100
      `}
    >
      {image && valid ? (
        <div className="flex flex-1 items-center justify-center text-gray-200">
          <img
            alt={name}
            onError={() => {
              setValid(false);
            }}
            src={image}
            className="h-32 w-32 mx-auto my-auto mb-2"
          />
        </div>
      ) : (
        <div className="h-32 flex flex-1 items-center justify-center text-gray-200">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="512"
            height="512"
            viewBox="0 0 512 512"
            fill="currentColor"
            className="h-20 w-20"
          >
            <g transform="translate(0 .004)">
              <path d="M424.17,80.6055451 C406.04,77.6055451 372.27,86.7855451 351.68,98.2955451 C334.68,88.7755451 309.23,81.2955451 290.53,80.5055451 C292.35,82.7155451 294.36,84.6755451 296.03,87.0855451 C316.87,117.235545 321.77,159.385545 318.2,191.995545 L478,191.995545 C483.25,152.525545 472.84,89.3455451 424.17,80.6055451 Z M210.53,50.4655451 C221.82,38.2755451 225,18.4355451 223.75,0.245545068 C210.87,-0.614454932 188.08,0.125545068 173.75,13.5255451 C157.2,30.1255451 160,59.8855451 160.51,63.7455451 C179,64.9955451 198.28,61.8955451 210.53,50.4655451 Z M402.22,50.4655451 C413.51,38.2755451 416.65,18.4655451 415.44,0.245545068 C402.56,-0.614454932 379.77,0.125545068 365.44,13.5255451 C348.91,30.1255451 351.67,59.8855451 352.22,63.7455451 C370.67,64.9955451 390,61.8955451 402.22,50.4655451 Z M232.48,80.6055451 C214.35,77.6055451 180.58,86.7855451 160,98.2955451 C139.34,86.7355451 105.57,77.5855451 87.52,80.6055451 C38.91,89.3355451 28.53,152.875545 33.7,191.995545 L286.3,191.995545 C291.56,152.525545 281.15,89.3455451 232.48,80.6055451 Z" />
              <path d="M496,223.995545 L16,223.995545 C7.163444,223.995545 0,231.158989 0,239.995545 L0,351.995545 L512,351.995545 L512,239.995545 C512,231.158989 504.836556,223.995545 496,223.995545 Z M64,303.995545 C55.163444,303.995545 48,296.832101 48,287.995545 C48,279.158989 55.163444,271.995545 64,271.995545 C72.836556,271.995545 80,279.158989 80,287.995545 C80,296.832101 72.836556,303.995545 64,303.995545 Z M448,303.995545 C439.163444,303.995545 432,296.832101 432,287.995545 C432,279.158989 439.163444,271.995545 448,271.995545 C456.836556,271.995545 464,279.158989 464,287.995545 C464,296.832101 456.836556,303.995545 448,303.995545 Z M0,495.995545 C0,504.832101 7.163444,511.995545 16,511.995545 L496,511.995545 C504.836556,511.995545 512,504.832101 512,495.995545 L512,383.995545 L0,383.995545 L0,495.995545 Z M448,431.995545 C456.836556,431.995545 464,439.158989 464,447.995545 C464,456.832101 456.836556,463.995545 448,463.995545 C439.163444,463.995545 432,456.832101 432,447.995545 C432,439.158989 439.163444,431.995545 448,431.995545 Z M64,431.995545 C72.836556,431.995545 80,439.158989 80,447.995545 C80,456.832101 72.836556,463.995545 64,463.995545 C55.163444,463.995545 48,456.832101 48,447.995545 C48,439.158989 55.163444,431.995545 64,431.995545 Z" />
            </g>
          </svg>
        </div>
      )}

      <div className="text-center text-sm font-extrabold">
        ${price.toFixed(2)}
      </div>
      <div className="w-full text-center text-xs font-medium text-gray-400">
        {brand}
      </div>
      <div className="w-full text-center text-xs font-medium capitalize truncate line-clamp-1">
        {_.toLower(name)}
      </div>
      <div className="w-full text-center text-xs text-gray-500 lowercase truncate line-clamp-1 mb-4">
        {description}
      </div>
      <Counter product={product} />
    </div>
  );
}

export default Card;
