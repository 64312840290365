import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { AnimatePresence, motion } from 'framer-motion';
import api from '../../../../api/api';
import {
  dayAtom,
  envAtom,
  locationAtom,
  serviceAtom,
  storeAtom,
  timeAtom,
  timeSlotAtom,
  tokenAtom,
} from '../../../../atoms/Atoms';
import { checkoutSelector } from '../../../../atoms/Selectors';
import Panel from '../../../shared-components/panel/Panel';
import Loader from '../../../shared-components/loader/Loader';
import { H3 } from '../../../shared-components/typography/Title';
import Subtitle from '../../../shared-components/typography/Subtitle';
import Form from '../../../shared-components/form/Form';
import Select from '../../../shared-components/form/Select';


require('dayjs/locale/es');

dayjs.locale('en');
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

function Hours() {
  const { i18n, t } = useTranslation();
  const checkout = useRecoilValue(checkoutSelector);
  const token = useRecoilValue(tokenAtom);
  const env = useRecoilValue(envAtom);
  const store = useRecoilValue(storeAtom);
  const service = useRecoilValue(serviceAtom);
  const [days, setDays] = useState([]);
  const [times, setTimes] = useState([]);
  const [day, setDay] = useRecoilState(dayAtom);
  const [time, setTime] = useRecoilState(timeAtom);
  const setTimeSlot = useSetRecoilState(timeSlotAtom);
  const location = useRecoilValue(locationAtom);

  useEffect(() => {
    if (store && token) {
      if (service === 0 || (service === 1 && location.location !== null)) {
        api
          .post(
            `business/hours/${store.locations[0].id}`,
            {
              OrderTotal: checkout.subtotal,
              ItemCount: checkout.itemCount,
              OrderType: service,
              DeliveryLocationId: service === 1 ? location?.location?.id : null,
              BusinessItemIds: _.map(checkout.order, (e) => e.id)
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            setDays(response.data.data.results);
            setTimes(response.data.data.results[0].times);
          })
          .catch((error) => console.log(error));
      }
    }
  }, [token, store, location]);

  useEffect(() => {
    if (day) {
      const filter = _.filter(days, (d) => d.date === day);
      if (filter.length > 0) {
        setTimes(filter[0].times);
      }
    }
  }, [day]);

  useEffect(() => {
    if (time) {
      const filtered = _.filter(times, (ti) => ti.time === time);
      setTimeSlot(filtered[0]);
    }
  }, [time]);

  return (
    <Panel>
      <H3 className="font-heading" text={t('date')} />
      <Subtitle
        text={t('service_date_description')}
      />
      <span className="text-red-500 font-medium text-xs">
        {service === 1 ? `*${t('delivery_time_slot_warning')}` : `*${t('pickup_time_slot_warning')}`}
      </span>
      <div className="flex items-center justify-center">
        {days.length === 0 && (
          <div
            style={{ height: 78 }}
            className="flex items-center justify-center"
          >
            <Loader />
          </div>
        )}
        <AnimatePresence>
          {days.length > 0 && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
              exit={{ opacity: 0 }}
              className="w-full "
            >
              <Form>
                <Select
                  onChange={(e) => setDay(e.target.value)}
                  options={[
                    {
                      key: 'placeholder',
                      value: null,
                      text: `${t('select_date_placeholder')}`,
                    },
                    ..._.map(days, (d, index) => ({
                      key: `day-${index}`,
                      value: d.date,
                      text: dayjs(d.date)
                        .tz(env?.timezone).locale(i18n)
                        .format('dddd, D [de] MMMM, YYYY'),
                    })),
                  ]}
                />
                <Select
                  disabled={!day}
                  onChange={(e) => setTime(e.target.value)}
                  options={[
                    {
                      key: 'placeholder',
                      value: null,
                      text: `${t('select_time_placeholder')}`,
                    },
                    ..._.map(times, (h, index) => ({
                      key: `hour-${index}`,
                      value: h.time,
                      disabled: !h.isAvailable,
                      text: `${dayjs(h.time).tz(env?.timezone).locale(i18n).format('h:mm A')} - ${dayjs(h.endTime).tz(env?.timezone).locale(i18n).format('h:mm A')}`,
                    })),
                  ]}
                />
              </Form>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </Panel>
  );
}

export default Hours;
