import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookSquare,
  faInstagramSquare,
} from '@fortawesome/free-brands-svg-icons';
import goodiesleaf from '../../../../../assets/img/lhf/goodiesandleaf.svg';

function Footer() {
  const { t } = useTranslation();
  return (
    <div>
      <div className="flex lg:hidden flex-col items-center justify-center text-xs p-6 bg-secondary h-min">
        <img
          alt="Logo"
          src={goodiesleaf}
          className="bg-secondary w-28 md:w-48"
        />
        <div className="hidden space-x-2 text-lg text-white hover:text-white transition duration-500 ease-in-out">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/lahaciendafoods/"
          >
            <FontAwesomeIcon icon={faFacebookSquare} />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/lahaciendafoods/"
          >
            <FontAwesomeIcon icon={faInstagramSquare} />
          </a>
        </div>     
        <div className="text-white font-light">
          Copyright &copy; {new Date().getFullYear()} La Hacienda 
        </div>
        <nav className="flex items-center justify-center text-xs space-x-2">
            <NavLink
              to="/terms"
              className="text-xs hover:font-semibold text-white hover:opacity-50 ring-0 focus:ring-0 outline-none focus:outline-none transition ease-in-out duration-500"
            >
              {t('terms')}
            </NavLink>
            <NavLink
              to="/privacy"
              className="text-xs hover:font-semibold text-white hover:opacity-50 ring-0 focus:ring-0 outline-none focus:outline-none transition ease-in-out duration-500"
            >
              {t('privacy')}
            </NavLink>
        </nav>
      </div>
      <div className="hidden lg:flex grid grid-cols-2 text-xs p-6 bg-secondary h-min">
        <div className="flex-col flex-1 items-start">
          <div className="hidden space-x-2 text-lg text-white hover:text-white transition duration-500 ease-in-out">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/lahaciendafoods/"
            >
              <FontAwesomeIcon icon={faFacebookSquare} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/lahaciendafoods/"
            >
              <FontAwesomeIcon icon={faInstagramSquare} />
            </a>
          </div>     
          <div className="text-white font-light">
            Copyright &copy; {new Date().getFullYear()} La Hacienda 
          </div>
          <nav className="flex items-start text-xs space-x-2">
              <NavLink
                to="/terms"
                className="text-xs hover:font-semibold text-white hover:opacity-50 ring-0 focus:ring-0 outline-none focus:outline-none transition ease-in-out duration-500"
              >
                {t('terms')}
              </NavLink>
              <NavLink
                to="/privacy"
                className="text-xs hover:font-semibold text-white hover:opacity-50 ring-0 focus:ring-0 outline-none focus:outline-none transition ease-in-out duration-500"
              >
                {t('privacy')}
              </NavLink>
          </nav>
        </div>
        <div className="flex-col flex-1 justify-items-end items-end">
          <img
            alt="Logo"
            src={goodiesleaf}
            className="bg-secondary w-28 md:w-48 float-right"
          />
        </div>
      </div>
    </div>
  );
}

export default Footer;
