import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useParams } from 'react-router-dom';
import RegisterForm from './shared-components/RegisterForm';
import { H1 } from '../../shared-components/typography/Title';

function Register() {
  const { friendCode } = useParams();
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <div className="flex-1 overflow-y-auto">
      <div className="flex flex-col justify-center py-6 px-4 md:px-0">
        <div className="sm:mx-auto sm:w-full sm:max-w-lg">
          <H1 text={t('create_account')} />
          <div className="text-xs tracking-tight space-x-1">
            <span>{t('already_registered')}</span>
            <Link
              to={{ pathname: '/login', state: { from: location.pathname } }}
              className="text-accent hover:text-accent transform duration-500 ease-in-out"
            >
              {t('login_here')}
            </Link>
          </div>
          <RegisterForm referral={friendCode} />
        </div>
      </div>
    </div>
  );
}

export default Register;
