import React from 'react';

function AdminAvatar({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="698"
      height="698"
      viewBox="0 0 698 698"
      className={className}
    >
      <defs>
        <linearGradient id="avatar-a" x1="50%" x2="50%" y1="100%" y2="0%">
          <stop offset="0%" stopColor="gray" stopOpacity=".25" />
          <stop offset="54%" stopColor="gray" stopOpacity=".12" />
          <stop offset="100%" stopColor="gray" stopOpacity=".1" />
        </linearGradient>
      </defs>
      <g fill="none">
        <circle cx="349" cy="349" r="349" fill="url(#avatar-a)" opacity=".5" />
        <circle cx="349.68" cy="346.77" r="341.64" fill="#F5F5F5" />
        <path
          fill="#FDE68A"
          d="M350,689.760505 C416.756514,689.868514 482.067959,670.322728 537.79,633.56 C525.2,564.76 477.29,560.84 477.29,560.84 L213.09,560.84 C213.09,560.84 167.88,564.55 153.76,627.84 C211.209193,668.250502 279.761807,689.881046 350,689.760505 L350,689.760505 Z"
        />
        <circle cx="346.37" cy="339.57" r="164.9" fill="#333" />
        <path
          fill="#000"
          d="M293.15,476.92 L398.81,476.92 L398.81,561.45 C398.81,590.619395 375.169393,614.268957 346,614.28 L346,614.28 C316.822797,614.28 293.17,590.627203 293.17,561.45 L293.17,476.92 L293.15,476.92 Z"
          opacity=".1"
        />
        <path
          fill="#FDB797"
          d="M296.5,473 L395.5,473 C397.350154,473 398.850001,474.499846 398.850001,476.35 L398.850001,557.53 C398.852654,571.546586 393.285078,584.989704 383.372917,594.899989 C373.460757,604.810275 360.016585,610.375307 346,610.370004 L346,610.370004 C316.822797,610.370004 293.17,586.717203 293.17,557.54 L293.17,476.35 C293.17,474.507629 294.657661,473.010999 296.5,473 L296.5,473 Z"
        />
        <path
          fill="#000"
          d="M293.34,516.82 C327.393232,529.54254 364.877444,529.645421 399,517.11 L399,504.11 L293.34,504.11 L293.34,516.82 Z"
          opacity=".1"
        />
        <circle cx="346.37" cy="372.44" r="151.45" fill="#FDB797" />
        <path
          fill="#000"
          d="M238.49,234.68 C238.49,234.68 302.32,364.24 482.37,289 L440.45,223.27 L366.14,196.6 L238.49,234.68 Z"
          opacity=".1"
        />
        <path
          fill="#333"
          d="M238.49,232.78 C238.49,232.78 302.32,362.34 482.37,287.08 L440.45,221.35 L366.14,194.68 L238.49,232.78 Z"
        />
        <path
          fill="#333"
          d="M237.93,224 C242.23379,210.6772 249.672083,198.58183 259.62,188.73 C289.41,159.28 338.25,153.07 363.3,119.49 C369.3,128.81 364.66,143.14 354.3,147.14 C378.3,146.98 406.11,144.88 419.68,125.14 C426.525281,141.075699 423.578196,159.529049 412.11,172.54 C433.38,173.54 456.11,187.94 457.45,209.19 C458.37,223.35 449.45,236.75 437.86,244.87 C426.27,252.99 412.15,256.72 398.3,259.77 C357.86,268.7 211.54,306.07 237.93,224 Z"
        />
        <ellipse cx="194.86" cy="372.3" fill="#FDB797" rx="14.09" ry="26.42" />
        <ellipse cx="497.8" cy="372.3" fill="#FDB797" rx="14.09" ry="26.42" />
      </g>
    </svg>
  );
}

export default AdminAvatar;
