import React from 'react';
import { useTranslation } from 'react-i18next';
// import { useHistory } from 'react-router';
import { motion } from 'framer-motion';
import { useUnmount } from 'react-use';
import { useSetRecoilState } from 'recoil';
import Animation from '../../shared-components/Animation/Animation';
import goodieslogo from '../../../assets/img/bag.png';
import Banner from './shared-components/banner/Banner';
import Footer from './shared-components/footer/Footer';
import Carousel from './shared-components/carousel/Carousel';
import Services from './shared-components/services/Services';
import StoreDialog from './shared-components/StoreDialog';
import Content from './shared-components/content/Content';
import { storesDialogAtom } from '../../../atoms/Atoms';



function Home() {
  const { t } = useTranslation();
  const setDialog = useSetRecoilState(storesDialogAtom);

  useUnmount(() => {
    setDialog(false);
  });

  return (
    <Animation className="flex flex-col flex-1 justify-between">
      <div className="grid grid-cols-1 md:grid-cols-2 flex flex-1 bg-bottom bg-cover bg-bag md:bg-bagVer">
        <div className="flex flex-col flex-1 justify-between p-4">
          <div className="flex flex-1 justify-start md:justify-center text-center flex-col">
            <div className="mx-auto hidden">
              <img src={goodieslogo} alt="Goodies for Foodies" className="h-full w-auto" />
            </div>
            <div className="hidden text-xl font-light text-gray-500 tracking-tight mb-4">
              {t('experience')}
            </div>       
            <div className="flex items-center space-x-4 mb-4">
              <Content />
              <StoreDialog />         
            </div>       
          </div>
        </div>
        <section className="hidden md:flex flex-1 flex-shrink-0">
          <div className="flex flex-col flex-1 justify-between">
            <div>
              <img 
                hidden
                src={goodieslogo}
                alt="Goodies Logo"
                className="h-7 w-auto object-none object-center"
              />
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </Animation>
  );
}

export default Home;
