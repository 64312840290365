import React, { useEffect, useRef } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { useClickAway } from 'react-use';
import { AnimatePresence, motion } from 'framer-motion';
import { modalAtom, productAtom, timeAtom } from '../../../atoms/Atoms';
import Image from './shared-components/Image';
import Price from '../Price';
import Related from './shared-components/Related';
import BoughtTogether from './shared-components/BoughtTogether';
import Counter from '../Counter';
import { backdropVariants } from '../../../utils';
import ItemTimeConstraint from '../ItemTimeConstraint';

function Modal() {
  const { t } = useTranslation();
  const ref = useRef();
  const [showModal, setShowModal] = useRecoilState(modalAtom);
  const [product, setProduct] = useRecoilState(productAtom);

  useEffect(() => {
    if (!showModal) setProduct(null);
  }, [showModal]);

  useClickAway(ref, () => {
    setShowModal(false);
  });

  return (
    <AnimatePresence>
      {showModal && (
        <motion.div
          variants={backdropVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          className="fixed bg-gray-800 inset-0 bg-opacity-75 z-10 flex items-end md:items-center justify-center px-0 md:px-4"
        >
          <div className="text-gray-800 antialiased justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative my-6 mx-auto w-full md:px-32 lg:px-0 lg:w-1/2 px-4">
              <div
                ref={ref}
                className="border-0 rounded-xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-6"
              >
                <div className="flex items-center justify-end">
                  <button
                    type="button"
                    onClick={() => setShowModal(false)}
                    className="hover:opacity-75 outline-none focus:outline-none"
                  >
                    <FontAwesomeIcon icon={faTimesCircle} className="text-xl" />
                  </button>
                </div>
                <div className="flex space-x-4 md:mb-10">
                  <Image name={product.name} image={product.imageUrl} />
                  <div className="flex flex-col flex-1">
                    <div className="text-gray-500 text-xs">
                      {product.sectionName}
                    </div>
                    <div className="font-light tracking-tight text-xs md:text-sm">
                      {product.brand}
                    </div>                   
                    <div className="font-extrabold leading-8 font-heading text-secondary tracking-tight text-xl md:text-3xl capitalize">
                      {_.toLower(product.name)}
                    </div>                    
                    <div className="capitalize font-light tracking-tight text-xs md:text-sm">
                      {_.toLower(product.description)}
                    </div>
                    <Price
                      className="text-xs md:text-sm"
                      hasRandomWeight={product.hasRandomWeight}
                      weightType={product.weightType}
                      unitPriceFactor={product.unitPriceFactor}
                      price={product.activePrice}
                    />
                    <br />
                    {!product.isAvailable && (
                      <div className="mb-4">
                        <span className="text-xs font-semibold tracking-tight rounded-full bg-accent text-white px-2 py-1 ring-2 ring-rose-500 ring-offset-2">
                          {t('not_available')}
                        </span>
                      </div>
                    )}
                    <div className="hidden md:block">
                      <div className="text-gray-500 text-xs mb-4">
                        {t('weight_description')}
                      </div>
                      {product.isAvailable ? <Counter product={product} /> : null}
                      <ItemTimeConstraint product={product} />
                    </div>
                  </div>
                </div>
                <div className="block md:hidden">
                  <div className="text-gray-500 text-xs mb-4">
                    {t('weight_description')}
                  </div>
                  <Counter className="mx-auto" product={product} />
                  <ItemTimeConstraint product={product} />
                </div>
                <Related product={product.id} />
                <BoughtTogether product={product.id} />
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default Modal;
