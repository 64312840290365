import React from 'react';
import { useLocalStorage } from 'react-use';

function Avatar() {
  const [user] = useLocalStorage('lhf-user');

  if (user) {
    return (
      <img
        alt={user.completeName}
        src={user.avatar}
        className="rounded-full h-8 w-8 bg-gray-200 mt-1"
      />
    );
  }

  return null;
}

export default Avatar;
