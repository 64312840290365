import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useRecoilValueLoadable } from 'recoil';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import Header from '../../shared-components/header/Header';
import { ordersSelector } from '../../../atoms/Selectors';

require('dayjs/locale/es');

dayjs.locale('en');
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

function Orders() {
  const orders = useRecoilValueLoadable(ordersSelector);
  console.log(orders);

  return (
    <div>
      <Header />
      <div>{orders && orders.length}</div>
      <div className="flex flex-col md:flex-row flex-1 overflow-hidden">
        test orders
      </div>
    </div>
  );
}

export default Orders;
