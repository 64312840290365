import React from 'react';
import { useTranslation } from 'react-i18next';
import { mapWeightTypeAbbr } from '../../functions';

function Price({ price, hasRandomWeight, weightType, unitPriceFactor,
  center, showPrice, showDesc, className }) {
  const showPriceLocal = showPrice ?? false;
  const showDescLocal = showDesc ?? false;
  const { t } = useTranslation();
  if (hasRandomWeight && weightType === 4) {
    return (
      <div className={`${className} ${center ? 'text-center' : ''} text-xs text-secondary tracking-tight`}>
        <div hidden={!showPriceLocal} className="text-xs font-bold">
          ${parseFloat(price).toFixed(2)}/lb.
        </div>
        <div hidden={!showDescLocal}>
          <span className="text-xtiny pr-1">{(parseFloat(1) / parseFloat(unitPriceFactor)).toFixed(2)} lb.</span>
          <span className="text-xs">${(parseFloat(price) / parseFloat(unitPriceFactor)).toFixed(2)}</span>
        </div>
       
      </div>
    );
  }

  return (
    <div hidden={!showPriceLocal} className={`${className} ${center ? 'text-center' : ''} text-xs text-secondary font-bold tracking-tight`}>
      ${parseFloat(price).toFixed(2)}{hasRandomWeight && `/${mapWeightTypeAbbr(weightType)}`}
    </div>
  );
}

export default Price;
